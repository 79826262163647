import styled from 'styled-components'
import { animated } from 'react-spring'

/**
 * InputGroup
 * @description Group Inputs together with same size horizontally
 */

export const InputGroup = styled(animated.div)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  > div {
    flex: 1;
    margin: 0 5px;
  }
  > div:first-child {
    margin-left: 0px;
  }
  > div:last-child {
    margin-right: 0px;
  }
`
