import React, { useState } from 'react'
import { NavBarContainer, NavBarList, Li, NavLink, Logo } from './styles'
import LOGO from '../../assets/images/logo.png'
import { firebaseSignOut } from '../../services/firebase/firebaseAuth'
import useSession from '../../config/hooks/session'

interface NavBarProps extends React.HTMLAttributes<HTMLDivElement> {}

const NavBar = ({ ...otherProps }: NavBarProps) => {
  const [tabSelected, setTabSelected] = useState('')

  const handleLogout = () => firebaseSignOut()

  const handleTabClick = (tab: string) => setTabSelected(tab)

  const isSelected = (tab: string) => {
    if (tab === tabSelected) return true
    return false
  }

  const user = useSession()

  return (
    <NavBarContainer {...otherProps}>
      <NavBarList>
        <Li>
          <Logo src={LOGO} />
        </Li>
        <Li selected={isSelected('fleets')}>
          <NavLink selected={isSelected('fleets')} onClick={() => handleTabClick('fleets')} to="/fleets">
            Fleets
          </NavLink>
        </Li>
        <Li selected={isSelected('silos')}>
          <NavLink selected={isSelected('silos')} onClick={() => handleTabClick('silos')} to="/silos">
            Silos
          </NavLink>
        </Li>
        <Li selected={isSelected('customers')}>
          <NavLink selected={isSelected('customers')} onClick={() => handleTabClick('customers')} to="/customers">
            Customers
          </NavLink>
        </Li>
        {user.role !== 'OPERATOR' && (
          <Li selected={isSelected('smartsand-users')}>
            <NavLink
              selected={isSelected('smartsand-users')}
              onClick={() => handleTabClick('smartsand-users')}
              to="/smartsand-users"
            >
              Smart Sand
            </NavLink>
          </Li>
        )}
        <Li selected={isSelected('silo-types')}>
          <NavLink selected={isSelected('silo-types')} onClick={() => handleTabClick('silo-types')} to="/silo-types">
            Silo Type
          </NavLink>
        </Li>
        <Li>
          <NavLink to="" as="button" onClick={handleLogout}>
            Logout
          </NavLink>
        </Li>
      </NavBarList>
    </NavBarContainer>
  )
}

export default NavBar
