import { useEffect, useState } from 'react'
import { useSpring } from 'react-spring'
import { MessageProps } from '../../components/Toast'
import { fetchSingleFleet, RetrieveSingleFleet } from '../../services/fleets'
import { fetchAllAssignedModems, RetrieveAllModems } from '../../services/modems'

export type ModemList = {
  alias: string
  mdmid: string
  siloUid: string
  siloId: string
}

const useSingleFleet = (id: string) => {
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const [fleetId, setFleetId] = useState('')
  const [location, setLocation] = useState('')
  const [customerInput, setCustomerInput] = useState({ value: '', label: '' })
  const [modemList, setModemList] = useState<ModemList[]>([])
  const [assignedSilos, setAssignedSilos] = useState([
    { modemId: '', assignedSilo: '', assignedSiloUid: '', assignedSiloSortOrder: 0 },
  ])
  const [loadingData, setLoadingData] = useState(true)
  const [props, set] = useSpring(() => ({ opacity: 0 }))

  useEffect(() => {
    const getSingleFleet = async () => {
      try {
        const modemsResponse: RetrieveAllModems = await fetchAllAssignedModems()
        const { data } = modemsResponse.data

        const list = data.map(modem => ({
          alias: modem.alias,
          mdmid: modem.mdmid,
          siloUid: modem.silo ? modem.silo.id : '',
          siloId: modem.silo ? modem.silo.siloId : '',
        }))

        setModemList(list)

        if (id) {
          const fleetsResponse: RetrieveSingleFleet = await fetchSingleFleet(id)
          const { fleetId, customer, location, silos } = fleetsResponse.data.data
          if (customer) setCustomerInput({ value: customer.id, label: customer.name })
          setLocation(location)
          setFleetId(fleetId)
          if (silos && silos.length > 0) {
            const selectedSilos = silos.map(silo => {
              return {
                modemId: silo.positionData.mdmid,
                assignedSilo: silo.siloId,
                assignedSiloUid: silo.id,
                assignedSiloSortOrder: silo.sortOrder,
              }
            })
            setAssignedSilos(selectedSilos)
          }
        }
      } catch (error) {
        if (error.response.status === 400) {
          setToast({
            value: "The fleet you are trying to access, doesn't seem to exist.",
            type: 'error',
            redirect: '/fleets',
          })
        } else {
          setToast({ value: error.response.data.error.message, type: 'error' })
        }
      }

      set({ opacity: 1 })
      setLoadingData(false)
    }
    getSingleFleet()
  }, [id, set])

  return {
    fleetId,
    setFleetId,
    customerInput,
    location,
    setLocation,
    setCustomerInput,
    assignedSilos,
    setAssignedSilos,
    modemList,
    loadingData,
    toast,
    setToast,
    props,
  }
}

export default useSingleFleet
