import styled, { keyframes } from 'styled-components'
import { Z_INDEX } from '../../config/styles/constants'
import { PRIMARY, DANGER, COOL_GREY, SUCCESS } from '../../config/styles/colours'

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.modal};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
`

const rotate = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  50% {
    transform: rotate3d(0, 0, 1, 180deg);
  }

  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }

`
const percentage = keyframes`
  0% {
    background-color: ${SUCCESS}
    height: 0%;
    bottom: 0;
  }

  25% {
    background-color: ${SUCCESS};
    height: 100%;
    bottom: 0;
  }

  50% {
    background-color: ${SUCCESS};
    height: 0%;
    bottom: 0;
  }

  50.001% {
    background-color: ${DANGER};
    height: 0%;
    bottom: auto;
  }

  75% {
    background-color: ${DANGER}
    height: 100%;
    bottom: auto;
  }

  99.999% {
    background-color: ${DANGER}
    height: 0%;
    bottom: auto;
  }
  100% {
    background-color: ${SUCCESS}
    height: 0%;
    bottom: 0;
  }
`

export const SquareSpinner = styled.div`
  border: 4px solid ${COOL_GREY};
  width: 30px;
  height: 60px;
  position: relative;
  animation: ${rotate} 4s 1s cubic-bezier(0.77, 0, 0.175, 1) infinite;
`

export const InsideAnimation = styled.div`
  width: 100%;
  height: 0%;
  background-color: ${SUCCESS};
  position: absolute;
  bottom: 0;
  animation: ${percentage} 4s cubic-bezier(0.77, 0, 0.175, 1) infinite;
`
export const LoadingMessage = styled.p`
  color: ${PRIMARY};
  margin-top: 20px;
  font-size: 22px;
  font-weight: bold;
`
