import styled, { css } from 'styled-components'
import { animated } from 'react-spring'
import { DANGER } from '../../config/styles/colours'

interface InputStyledProps {
  error?: string
  labelLeft?: boolean
  required?: boolean
}

const Required = css`
  > p:after {
    content: '*';
    color: red;
    margin-left: 3px;
  }
`

export const Label = styled.label<InputStyledProps>`
  display: flex;
  flex-direction: ${({ labelLeft }) => (labelLeft ? 'row' : 'column')};
  align-items: flex-start;
  justify-content: space-around;
  transition: all 0.3s ease-in-out;
  margin-bottom: ${({ labelLeft }) => (labelLeft ? 0 : 12)}px;
  align-items: ${({ labelLeft }) => (labelLeft ? 'center' : 'none')};
  ${({ required }) => required && Required}
`

export const LabelText = styled.p<InputStyledProps>`
  margin-bottom: ${({ labelLeft }) => (labelLeft ? 0 : 5)}px;
  margin-right: ${({ labelLeft }) => (labelLeft ? 5 : 0)}px;
  &:after {
    content: ':';
  }
`
export const ErrorText = styled(animated.p)`
  font-size: 10px;
  margin-bottom: 10px;
  text-align: left;
  margin-top: -8px;
  color: ${DANGER};
`
export const InfoText = styled.p`
  font-size: 10px;
  margin-bottom: 10px;
  text-align: left;
  margin-top: -8px;
`
