import styled from 'styled-components'
import { default as Select } from 'react-select'
import { GlobalContainer } from '../../../../components/GlobalContainer'
import SimpleLink from '../../../../components/SimpleLink'
import { BLACK, PRIMARY, DANGER } from '../../../../config/styles/colours'
import Button from '../../../../components/Button'

export const BackTo = styled(SimpleLink)`
  align-self: flex-start;
  font-weight: bold;
`

export const AddEditFooter = styled.div`
  display: flex;
  align-items: center;
  ${GlobalContainer}
  margin-top: 12px;
  > button {
    margin-bottom: 0;
    margin-right: 20px;
    &:nth-of-type(2) {
      color: ${BLACK};
    }
  }
`
export const AddAnotherButton = styled(Button)`
  align-self: flex-start;
  color: ${PRIMARY};
  padding: 0;
  margin-top: 12px;
`
export const RemoveSectionButton = styled(Button)`
  height: 16px;
  color: ${DANGER};
  align-self: center;
`
export const ErrorMessage = styled.p`
  color: ${DANGER};
`

export const CheckBoxContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  > p {
    margin-bottom: 0;
    margin-left: 12px;
    align-self: flex-end;
  }
`
export const DivBlock = styled.div`
  display: block;
`
export const StyledSelect = styled(Select)`
  width: 100%;
`
export const LoadingContainer = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
  > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`
