import { Location } from 'history'
import React, { useState } from 'react'
import { match } from 'react-router'
import Container from '../../components/Container'
import DataTable from '../../components/DataTable'
import Toast from '../../components/Toast'
import useCustomerUsers from '../../config/hooks/useCustomerUsers'
import { deleteUsers } from '../../services/user'
import { BackTo } from './styles'

interface CustomerUserProps {
  location: Location<{ routerNotification?: string }>
  match: match<{ id: string }>
}

const CustomerUsers = ({ location, match }: CustomerUserProps) => {
  const { state } = location
  const { id } = match.params
  const {
    users,
    setUsers,
    customer,
    loading,
    setLoading,
    toast,
    setToast,
    page,
    setPage,
    totalPages,
    totalUsers,
    resetData,
  } = useCustomerUsers(id)
  const [toastMessage, setToastMessage] = useState({ value: (state && state.routerNotification) || '' })

  const handleDelete = async (ids: string[]) => {
    try {
      setLoading(true)
      await deleteUsers(ids)

      setUsers(users.filter(u => !ids.includes(u.id)))

      if (ids.length > 1) {
        setToast({ value: 'All Fleets selected have been deleted successfully' })
      } else {
        setToast({ value: 'User has been deleted successfully' })
      }
    } catch (error) {
      setToast({ value: error.response.data.error.reason, type: 'error' })
    }

    setLoading(false)
  }

  return (
    <>
      <Toast message={toastMessage || toast} action={setToastMessage || setToast} />
      <Container title={customer && customer.name} titleMargin={12}>
        <BackTo to="/customers" icon="ArrowLeft">
          Back to all customers
        </BackTo>
        <DataTable
          headerTitles={['User Email', 'Fleets', 'Last Update']}
          dataToDisplay={['email', 'accessibleFleets', 'lastUpdatedAt']}
          data={users}
          title="Users"
          addNewButton="Add User"
          addNewRoute={`/new-customer-user/${id}`}
          editRoute="/customer-user"
          loading={loading}
          deleteItem={handleDelete}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          totalItems={totalUsers}
          resetSortData={resetData}
        />
      </Container>
    </>
  )
}

export default CustomerUsers
