import React, { useEffect } from 'react'
import { ToastContainer, Message, Timer } from './styles'
import { AlertCircle, AlertOctagon, CheckCircle } from 'react-feather'
import { useTransition, useSpring } from 'react-spring'
import history from '../../config/router'

type ToastProps = {
  action: React.Dispatch<any>
  message: MessageProps
}

export type MessageProps = {
  value?: string
  type?: 'success' | 'error' | 'info'
  redirect?: string // URL redirection on the timer animation end (i.e: /silos)
}

/**
 * Toast
 * @param action - Set state function (hook)
 * @param message - Pass the message as an object, value for the actual message and type will define the background colour, types are success, error, info
 */

const Toast = ({ message: { value = '', type = 'success', redirect }, action }: ToastProps) => {
  const fade = useTransition(value, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  const timer = useSpring({
    from: { width: '100%' },
    to: { width: '0%' },
    config: { duration: 3000 },
    onRest: () => {
      if (redirect) history.push(redirect)
    },
  })

  useEffect(() => {
    const timeOut = setTimeout(() => {
      action({ value: '', type: '' })
    }, 3000)
    return () => clearTimeout(timeOut)
  }, [action, type])

  const SelectIcon = () => {
    switch (type) {
      case 'error':
        return <AlertOctagon color="white" />
      case 'info':
        return <AlertCircle color="white" />
      default:
        return <CheckCircle color="white" />
    }
  }

  return (
    <>
      {fade.map(
        ({ item, key, props }) =>
          item && (
            <ToastContainer key={key} type={type} style={props}>
              <SelectIcon />
              <Message>{value}</Message>
              <Timer style={timer} />
            </ToastContainer>
          ),
      )}
    </>
  )
}

export default Toast
