import React, { useState, useRef } from 'react'
import { animated } from 'react-spring'
import { match } from 'react-router'
import Container from '../../../../components/Container'
import InputSection from '../../../../components/InputSection'
import InputText from '../../../../components/InputText'
import { AddEditFooter, BackTo, NoModemContainer } from './styles'
import Button from '../../../../components/Button'
import Toast from '../../../../components/Toast'
import Spinner from '../../../../components/Spinner'
import DropDown from '../../../../components/DropDown'
import SiloDetailsGroup from './components/SiloDetailsGroup'
import { InputGroup } from '../../../../components/InputGroup'
import useSingleSilo from '../../../../config/hooks/useSingleSilo'
import { fetchFleetsByName } from '../../../../services/fleets'
import { Fleet } from '../../../../config/types'
import { ValueType } from 'react-select/src/types'
import { CustomAsyncSelect } from '../../../Fleets/screens/FleetAddEdit/styles'
import InputWrapper from '../../../../components/InputWrapper'
import { fetchSingleModem, udpateSingleSilo, createSingleSilo } from '../../../../services/silos'

interface SilosAddEditProps {
  match: match<{ id: string }>
}

const SilosAddEdit = ({ match }: SilosAddEditProps) => {
  const { id } = match.params
  const {
    loading,
    setLoading,
    siloUpdated,
    setSiloUpdated,
    siloDetails,
    setSiloDetails,
    loadingData,
    toast,
    setToast,
    silo,
    setSilo,
    props,
    models,
    modelIds,
    currentModel,
    setCurrentModel,
    modems,
    currentModem,
    setCurrentModem,
    fleet,
    setFleet,
  } = useSingleSilo(id)

  const siloIdRef = useRef<HTMLLabelElement>(null) // UI - Loading states

  const [loadingDetails, setLoadingDetails] = useState(false) // UI - Error management

  const [modemIdError, setModemIdError] = useState('')
  const [siloIdError, setSiloIdError] = useState('')
  const [siloModelError, setSiloModelError] = useState('')
  const [amberError, setAmberError] = useState('')
  const [redError, setRedError] = useState('')

  const handleSelectModem = async (alias: string) => {
    try {
      const modem = modems.find(modem => modem.alias && modem.alias === alias)
      if (!modem) {
        throw new Error(
          'Something went wrong when attempting to associate a modemid and an unit. Please contact support.',
        )
      }

      const { unitID: unitId } = modem

      setLoadingDetails(true)

      const results = await fetchSingleModem(unitId)
      const details = results.data.data

      setSiloUpdated({ ...siloUpdated, positionFleetUnitId: unitId })
      setCurrentModem(alias || 'No alias found')
      setSiloDetails({ ...details, ...siloDetails }) // Populate silo's details UI
    } catch (error) {
      setToast({ value: error.message, type: 'error' })
    }
    setLoadingDetails(false)
  }

  const handleSearchFleet = async (inputValue: string) => {
    try {
      if (inputValue) {
        const response = await fetchFleetsByName({ fleetId: inputValue, take: 4, skip: 0 })
        const responseResults: { value: string; label: string }[] = []
        response.map((fleet: Fleet) => responseResults.push({ value: fleet.id, label: fleet.fleetId }))
        return responseResults
      }
    } catch (error) {
      setToast({ value: error.message, type: 'error' })
    }
  }

  const resetErrors = () => {
    if (modemIdError) {
      setModemIdError('')
    }

    if (siloIdError) {
      setSiloIdError('')
    }
    if (siloModelError) {
      setSiloModelError('')
    }
    if (amberError) {
      setAmberError('')
    }
    if (redError) {
      setRedError('')
    }
  }

  const manageErrors = () => {
    let error = 0
    if (!currentModem) {
      setModemIdError('This field is required, please add a model name for this fleet.')
      error += 1
    }

    if (!silo.siloId) {
      setSiloIdError('This field is required, please add a max capacity for this fleet.')
      error += 1
    }

    if (!currentModel) {
      setSiloModelError('This field is required, please select a Silo Model for this fleet.')
      error += 1
    }

    if (!siloDetails || !siloDetails.thresholdAmber) {
      setAmberError('This field is required, please add a percentage between 0 to 100')
      error += 1
    }

    if (
      siloDetails &&
      siloDetails.thresholdAmber &&
      (siloDetails.thresholdAmber > 100 || siloDetails.thresholdAmber < 1)
    ) {
      setAmberError('This field needs to be between 0 to 100')
      error += 1
    }

    if (
      siloDetails &&
      siloDetails.thresholdAmber &&
      siloDetails.thresholdRed &&
      siloDetails.thresholdAmber <= siloDetails.thresholdRed
    ) {
      setAmberError('The value needs to be higher than red')
      error += 1
    }

    if (!siloDetails || !siloDetails.thresholdRed) {
      setRedError('This field is required, please add a percentage between 0 to 100')
      error += 1
    }

    if (siloDetails && siloDetails.thresholdRed && (siloDetails.thresholdRed > 100 || siloDetails.thresholdRed < 1)) {
      setRedError('This field needs to be between 0 to 100')
      error += 1
    }

    if (
      siloDetails &&
      siloDetails.thresholdRed &&
      siloDetails.thresholdAmber &&
      siloDetails.thresholdRed >= siloDetails.thresholdAmber
    ) {
      setRedError('The value needs to be lower than amber')
      error += 1
    }

    return error
  }

  const handleUpdate = async () => {
    resetErrors()

    const countErrors = manageErrors()
    if (countErrors > 0) {
      return
    }

    setLoading(true)

    try {
      if (Object.keys(siloUpdated).length > 0) {
        const response = await udpateSingleSilo(silo.id, siloUpdated)
        setToast({ value: response.data.message })
      } else {
        setToast({ value: 'There is nothing to update.', type: 'info' })
      }
    } catch (error) {
      setToast({ value: error.message, type: 'error' })
    }
    setLoading(false)
  }

  const handleCreate = async () => {
    resetErrors()

    const countErrors = manageErrors()
    if (countErrors > 0) {
      return
    }

    setLoading(true)

    try {
      if (Object.keys(siloUpdated).length > 0) {
        const response = await createSingleSilo(siloUpdated)
        setToast({ value: response.data.message }) // Reset form

        setSilo({ id: '', siloId: '' })
        setSiloUpdated({})
        setFleet({ value: '', label: '' })
        setCurrentModel('')
        setCurrentModem('')
        setSiloDetails(undefined)
      } else {
        setToast({ value: 'There is nothing to update.', type: 'info' })
      }
    } catch (error) {
      setToast({ value: error.response.data.error.reason, type: 'error' })
    }

    setLoading(false)
  }

  if (loadingData) {
    return <Spinner message="Fetching Data..." />
  }

  return (
    <>
      <Toast message={toast} action={setToast} />

      <animated.div style={props}>
        <Container title={id ? 'Update Silo' : 'Add New Silo'} secondary>
          <BackTo to="/silos" icon="ArrowLeft">
            Back to Silos
          </BackTo>

          {modems.length > 0 ? (
            <>
              <InputSection title="Model Details">
                <InputGroup>
                  {id ? (
                    <InputText value={currentModem} label="SILO ID (Unit)" disabled />
                  ) : (
                    <DropDown
                      placeholder="Pick an ID"
                      options={modems.map(modem => modem.alias && modem.alias)}
                      disabled={loading}
                      label="SILO ID (Unit) Selection"
                      value={currentModem}
                      onClick={handleSelectModem}
                      required
                      error={modemIdError}
                      info="This can be found on the Silo Modem"
                    />
                  )}

                  <div />
                </InputGroup>

                <InputGroup>
                  <InputText
                    disabled={loading}
                    value={silo.siloId}
                    onChange={e => {
                      setSilo({ ...silo, siloId: e.target.value })
                      setSiloUpdated({ ...siloUpdated, siloId: e.target.value })
                    }}
                    required
                    label="Silo ID"
                    placeholder="Enter your Silo ID."
                    labelRef={siloIdRef}
                    error={siloIdError}
                    info="This must be unique"
                  />

                  <InputWrapper label="Fleet ID" info="Start typing a fleet Id to reveal options.">
                    <CustomAsyncSelect
                      isClearable
                      placeholder=""
                      isDisabled={loading}
                      cacheOptions
                      defaultOptions
                      onChange={(fleet: ValueType<any>) => {
                        setFleet(fleet)
                        setSiloUpdated({ ...siloUpdated, fleetId: fleet ? fleet.value : null })
                      }}
                      loadOptions={handleSearchFleet}
                      value={fleet || ''}
                    />
                  </InputWrapper>
                </InputGroup>

                <InputGroup>
                  <DropDown
                    placeholder="Pick a model"
                    optionIds={modelIds}
                    options={models}
                    disabled={loading}
                    label="Silo Model"
                    value={currentModel}
                    onClick={siloTypeId => {
                      setCurrentModel(siloTypeId)
                      setSiloUpdated({ ...siloUpdated, siloTypeId })
                    }}
                    required
                    error={siloModelError}
                  />

                  <div />
                </InputGroup>
              </InputSection>

              <InputSection title="Silo Details">
                <SiloDetailsGroup {...siloDetails} loading={loadingDetails} />
              </InputSection>

              <InputSection title="Silo Threshold">
                <InputGroup>
                  <InputText
                    type="number"
                    disabled={loading}
                    value={siloDetails ? siloDetails.thresholdAmber : ''}
                    onChange={e => {
                      setSiloDetails({ ...siloDetails, thresholdAmber: Number(e.target.value) })
                      setSiloUpdated({ ...siloUpdated, thresholdAmber: Number(e.target.value) })
                    }}
                    label="Amber"
                    placeholder="0%"
                    info="Capacity below this % will show as amber"
                    error={amberError}
                    group="%"
                    min="1"
                    max="99"
                    required
                  />

                  <InputText
                    type="number"
                    disabled={loading}
                    value={siloDetails ? siloDetails.thresholdRed : ''}
                    onChange={e => {
                      setSiloDetails({ ...siloDetails, thresholdRed: Number(e.target.value) })
                      setSiloUpdated({ ...siloUpdated, thresholdRed: Number(e.target.value) })
                    }}
                    label="Red"
                    placeholder="0%"
                    info="Capacity below this % will show as red"
                    error={redError}
                    group="%"
                    min="1"
                    max="99"
                    required
                  />
                </InputGroup>
              </InputSection>
            </>
          ) : (
            <NoModemContainer>
              <p>You can't add or edit a silo. All modems are assigned.</p>
            </NoModemContainer>
          )}
        </Container>

        {modems.length > 0 && (
          <AddEditFooter>
            <Button onClick={id ? handleUpdate : handleCreate} loading={loading}>
              {id ? 'Update' : 'Add'} Silo
            </Button>
          </AddEditFooter>
        )}
      </animated.div>
    </>
  )
}

export default SilosAddEdit
