import { useEffect, useState } from 'react'
import { MessageProps } from '../../components/Toast'
import { isSignInWithEmailLink, signInWithEmailLink } from '../../services/firebase/firebaseAuth'
import firebaseConfig from '../../services/firebase/firebaseConfig'
import history from '../router'
import { UserRole } from '../types'

export interface User extends firebase.User {
  role?: UserRole
}

/**
 * Custom hook which monitors the firebase authentication state
 */
const useAuth = () => {
  const [toast, setToast] = useState<MessageProps>({ value: '' })
  const [state, setState] = useState(() => {
    const user: User | null = firebaseConfig.auth().currentUser
    return {
      user,
      loading: !user,
    }
  })

  /**
   * This function below is based on the firebase doc and their code snippets
   * See: https://firebase.google.com/docs/auth/web/email-link-auth
   */
  const emailLinkSignIn = async () => {
    const emailLink = window.location.href
    if (isSignInWithEmailLink(emailLink)) {
      let email = window.localStorage.getItem('emailForSignIn')

      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation')
      }

      if (email) {
        try {
          await signInWithEmailLink(email, emailLink)
          window.localStorage.removeItem('emailForSignIn')
        } catch (error) {
          setToast({ value: error.message, type: 'error' })
        }
      }
    }
  }

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = firebaseConfig.auth().onAuthStateChanged(async user => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult()
        const role: UserRole = idTokenResult.claims.role

        setState({ user: { ...user, role }, loading: false })
      } else {
        /**
         * When opening the page for the first time, `onAuthStateChanged` is
         * triggered a first time (with user = null). We need to keep the loading
         * state if the user is currently attempting to sign in with an email
         * link.
         */
        const emailLink = window.location.href
        const isLoggingIn = isSignInWithEmailLink(emailLink)
        setState({ user, loading: isLoggingIn })

        // If user is not logging in, they may just have logged out. Redirect to /
        if (!isLoggingIn) {
          history.push('/')
        }
      }
    })

    // Handle firebase sign in with email link
    emailLinkSignIn()

    // Unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  return { ...state, toast, setToast }
}

export default useAuth
