import axios from 'axios'
import { SILO_TYPES_API } from './endpoints'
import { firebaseUser } from './firebase/firebaseAuth'
import { SiloType } from '../config/types'

export type RetrieveAllSiloTypes = { data: { data: { siloTypes: SiloType[]; total: number } } }
export type RetrieveSingleSiloType = {
  data: {
    data: {
      capacity: number
      model: string
    }
  }
}

export const fetchSiloTypes = async (take?: number, skip?: number) => {
  let params = {}
  if (take && skip) params = { take, skip }
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.get(SILO_TYPES_API, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return results as RetrieveAllSiloTypes
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchSingleSiloType = async (siloTypeUid: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.get(`${SILO_TYPES_API}/${siloTypeUid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return results as RetrieveSingleSiloType
  }

  throw new Error('User not found. Please sign in again.')
}

export const updateSingleSiloType = async (
  siloTypeUid: string,
  { model, capacity }: { capacity?: number; model?: string },
) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.post(
      `${SILO_TYPES_API}/${siloTypeUid}`,
      {
        model,
        capacity,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const createSingleSiloType = async (capacity: number, model: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.post(
      SILO_TYPES_API,
      {
        model,
        capacity,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const deleteSingleSiloType = async (siloTypeUid: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.delete(`${SILO_TYPES_API}/${siloTypeUid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const deleteSiloTypes = async (siloTypeIds: string[]) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.delete(`${SILO_TYPES_API}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        siloTypeIds,
      },
    })
    return results
  }

  throw new Error('User not found. Please sign in again.')
}
