import styled from 'styled-components'
import { PRIMARY, DANGER, PALE_GREY, LIGHT_GREY, BLACK, COOL_GREY } from '../../config/styles/colours'
import { BORDER_RADIUS } from '../../config/styles/constants'

interface InputStyledProps {
  error?: string
  group?: string
  autocomplete?: string
}

export const InputStyled = styled.input<InputStyledProps>`
  width: 100%;
  border-radius: ${BORDER_RADIUS}px;
  background-color: ${PALE_GREY};
  padding: 6px;
  outline: 0;
  border: 1px solid ${({ error }) => (error ? DANGER : LIGHT_GREY)};
  ${({ group }) => group && { borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
  color: ${({ error }) => (error ? DANGER : 'initial')};
  &:focus-within {
    border: 1px solid ${({ error }) => (error ? DANGER : PRIMARY)};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${LIGHT_GREY};
    color: ${COOL_GREY};
  }
`
export const GroupContainer = styled.div`
  display: flex;
  width: 100%;
`
export const Group = styled.div<InputStyledProps>`
  display: flex;
  align-items: center;
  border: 1px solid ${({ error }) => (error ? DANGER : LIGHT_GREY)};
  border-left: none;
  padding: 0 7px;
  border-top-right-radius: ${BORDER_RADIUS}px;
  border-bottom-right-radius: ${BORDER_RADIUS}px;
  background-color: ${LIGHT_GREY};
  > p {
    margin: 0;
    color: ${BLACK};
  }
`
