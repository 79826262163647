import React, { RefObject } from 'react'
import { Label, LabelText, ErrorText, InfoText } from './styles'
import { useTransition } from 'react-spring'

interface InputWrapper extends React.InputHTMLAttributes<HTMLLabelElement> {
  label?: string
  error?: string
  children?: React.ReactNode
  labelRef?: RefObject<HTMLLabelElement>
  labelLeft?: boolean
  info?: string
}

/**
 * InputWrapper - Provide a label and error to the component
 * @param label - Label text, also acts as the id
 * @param error - Display an error message on the bottom of the component
 * @param info - Info text for extra description
 * @param children - Pass any component as a children
 * @param labelRef - Create a reference for the label, use cases: focus on error
 */

const InputWrapper = ({
  label,
  error,
  children,
  labelRef,
  labelLeft,
  info,
  style,
  className,
  ...otherProps
}: InputWrapper) => {
  const fadeAnimation = useTransition(error, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  return (
    <div className={className} style={style}>
      <Label htmlFor={label} error={error} ref={labelRef} labelLeft={labelLeft} {...otherProps}>
        {label && <LabelText labelLeft={labelLeft}>{label}</LabelText>}
        {children}
      </Label>
      {info && <InfoText>{info}</InfoText>}
      {fadeAnimation.map(
        ({ item, key, props }) =>
          item && (
            <ErrorText key={key} style={props}>
              {item}
            </ErrorText>
          ),
      )}
    </div>
  )
}

export default InputWrapper
