import { RESPONSIVE_BREAKPOINTS } from '../../config/styles/constants'
import { css } from 'styled-components'

export const GlobalContainer = css`
  width: 65%;
  margin: 0 auto;
  @media (max-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    width: 90%;
  }
`
