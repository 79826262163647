import styled from 'styled-components'
import Select from 'react-select'
import { GlobalContainer } from '../../../../components/GlobalContainer'
import SimpleLink from '../../../../components/SimpleLink'

export const BackTo = styled(SimpleLink)`
  align-self: flex-start;
  font-weight: bold;
`

export const AddEditFooter = styled.div`
  ${GlobalContainer}
  margin-top: 12px;
`
export const SelectAssignedCustomers = styled(Select)`
  min-width: 100%;
`
