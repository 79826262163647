import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp, Plus, Settings, Trash } from 'react-feather'
import { animated, useTransition } from 'react-spring'
import history from '../../config/router'
import Button from '../Button'
import CheckBox from '../CheckBox'
// import Pagination from './components/Pagination'
import Modal from '../Modal'
import Pagination from './components/Pagination'
import {
  AddDataButton,
  ButtonDelete,
  Buttons,
  ButtonSettings,
  DTContainer,
  DTFooter,
  DTHead,
  FakeContent,
  FakeSelector,
  FakeSettings,
  RemoveDataButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  TitleAndCount,
} from './styles'
// import Pagination from './components/Pagination'

interface DataTableProps {
  disableSuperAdmin?: boolean
  headerTitles: string[]
  dataToDisplay: string[]
  data: any[]
  title: string
  addNewRoute: string
  removeAllButton?: string
  addNewButton?: string
  editRoute?: string
  loading?: boolean
  deleteItem?: (ids: Array<string>) => Promise<void>
  userRole?: string
  mapValues?: {
    key: string
    ids: string[]
    values: string[]
  }
  page?: number
  setPage?: React.Dispatch<React.SetStateAction<number>>
  totalPages?: number
  totalItems?: number
  resetSortData?: boolean
}

/**
 * DataTable
 * @param headerTitles - Set titles for thead
 * @param dataToDisplay - Add the object keys that you want to display
 * @param data - Array of objects data
 * @param title - Table title
 * @param removeAllButton - Display a button with this tittle for removing all selected items
 * @param addNewButton - Display a button with this tittle for adding a new something
 * @param addNewRoute - Path to go when the addNewButton is pressed
 * @param editRoute - Path to go when the settings button is pressed (edit data)
 * @param loading - Display a spinner when its fetching data to the table
 * @param deleteItem - Delete an item or an array of item
 * @param userRole - User Role
 * @param mapValues - Map a set of ids to return readable values
 * @param page - Current page number
 * @param setPage - Set page state
 * @param totalPages - Total number of pages
 * @param totalItems - Total number of items in all pages
 * @param resetSortData - Resets the Sort Data if filters / search are applied?
 */

const DataTable = ({
  headerTitles,
  dataToDisplay,
  data,
  title,
  removeAllButton,
  addNewButton,
  addNewRoute,
  editRoute,
  loading,
  deleteItem,
  userRole,
  mapValues,
  disableSuperAdmin,
  page,
  setPage,
  totalPages,
  totalItems,
  resetSortData,
}: DataTableProps) => {
  // ? adds by default the id, maybe this needs to change if instead id its uid
  dataToDisplay.push('id')
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [toggleDelete, setToggleDelete] = useState('')

  const [filterData, setFilterData] = useState<any[]>([])
  const [sortData, setSortData] = useState<string[]>([])
  const [sortStatus, setSortStatus] = useState<{
    up: boolean
    index: number | undefined
  }>({
    up: false,
    index: undefined,
  })

  useEffect(() => {
    if (resetSortData) {
      setSortData([])
      setSortStatus({
        up: false,
        index: undefined,
      })
    }

    if (data) {
      const filteredData = data.map(item => {
        return dataToDisplay.reduce((obj: any, key: string) => {
          if (key.includes('.')) {
            obj[key] = handleNestedParams(item, key)
          } else {
            obj[key] = item[key]
          }
          return obj
        }, {})
      })
      setFilterData(filteredData)
    }
  }, [resetSortData, data, dataToDisplay])

  const mapKey = (role: string, key: string) => {
    if (mapValues && key === mapValues.key) {
      const { values, ids } = mapValues

      // Map role to [values] only if role is not already
      // equal to one of the [values]
      if (!values.includes(role)) {
        return values[ids.findIndex((item: string) => item === role)]
      }
      return role
    }
    return role
  }

  /**
   * @param obj - final nested value we are looking for
   * @param key - nested paramter i.e: `siloType.model`
   */
  const handleNestedParams = (obj: any, key: string) => {
    let tempObj = { ...obj }
    const params = key.split('.')
    if (params && params.length > 0) {
      params.forEach((param: string) => {
        if (tempObj && tempObj.hasOwnProperty(param)) {
          tempObj = tempObj[param]
        } else {
          tempObj = null
        }
      })
    }
    return tempObj || ''
  }

  // * Select or deselect all or single id
  const handleSelect = (id: string) => {
    if (id === 'all') {
      const ids = data.map(item => item.id, [])
      if (JSON.stringify(ids) === JSON.stringify(selectedItems)) {
        setSelectedItems([])
        return
      }
      setSelectedItems(ids)
      return
    }
    if (selectedItems.includes(id)) {
      const filterItem = selectedItems.filter(x => x !== id)
      setSelectedItems([...filterItem])
      return
    }
    setSelectedItems([...selectedItems, id])
  }

  // * Check if this item is selected
  const isSelected = (id: string) => {
    return selectedItems.some(x => x === id)
  }

  const handleSort = (index: number) => {
    let results = []
    let up = true

    if (sortStatus.up && sortStatus.index === index) {
      // Descending
      results = filterData.sort((a: object, b: object) => compareItems(b, a, Object.keys(a)[index]))
      up = false
    } else {
      // Ascending
      results = filterData.sort((a: object, b: object) => compareItems(a, b, Object.keys(a)[index]))
    }

    setSortStatus({
      index,
      up,
    })

    setSortData(results)
  }

  /**
   * Compare objects in list based on a key
   * @param obj1 - object 1
   * @param obj2 - object 2
   * @param key  - object's key = parameter
   */
  const compareItems = (obj1: any, obj2: any, key: string) => {
    let item1 = obj1[key] && obj1[key].toString().toLowerCase()
    let item2 = obj2[key] && obj2[key].toString().toLowerCase()

    //* If comparing weight, remove the 'Lbs' and parse to compare
    if (key === 'sandData.currentWeight') {
      item1 = parseFloat(item1.slice(0, -3))
      item2 = parseFloat(item2.slice(0, -3))
    }

    switch (true) {
      // equal items sort equally
      case item1 === item2: {
        return 0
      }
      // undefined or null or empty sort after anything else
      case item1 === undefined || item1 === null || item1 === '': {
        return 1
      }
      case item2 === undefined || item2 === null || item2 === '': {
        return -1
      }
      default: {
        return item1 > item2 ? 1 : item2 > item1 ? -1 : 0
      }
    }
  }

  const renderHeader = headerTitles.map((title, index) => (
    <Th key={index}>
      <Button onClick={() => handleSort(index)} simple>
        {title} {sortStatus.up && sortStatus.index === index ? <ChevronUp size={12} /> : <ChevronDown size={12} />}
      </Button>
    </Th>
  ))

  const loadingData = () => (
    <tr>
      <Td>
        <FakeSelector>
          <div />
        </FakeSelector>
      </Td>
      {headerTitles.map(count => (
        <Td key={count}>
          <FakeContent />
        </Td>
      ))}
      <Td>
        <FakeSettings>
          <div />
          <div />
        </FakeSettings>
      </Td>
    </tr>
  )

  // * Renders the filtered Data
  const renderData = () => {
    const dataToUse = sortData && sortData.length > 0 ? sortData : filterData
    return dataToUse.map(item => {
      const disableAdmin = (disableSuperAdmin && item.role === 'SUPER_ADMIN') || false

      return (
        <tr key={item.id}>
          <Th>
            {userRole !== 'OPERATOR' && !disableAdmin && (
              <CheckBox toggle={isSelected(item.id)} onClick={() => handleSelect(item.id)} />
            )}
          </Th>

          {// ? ignores the id, maybe this needs to change if instead id its uid
          Object.keys(item).map((key, i) => {
            if (key !== 'id') {
              item[key] = mapKey(item[key], key)

              // * if the item its an array transform it to string and separate each element with a comma
              if (item[key] instanceof Array) {
                return <Td key={i}>{item[key].join(', ')}</Td>
              }

              // * if keys are equal to the possible dates in the types, convert to human date
              if (key === 'lastUpdatedAt' || key === 'createdAt' || key === 'locTime') {
                return <Td key={i}>{format(new Date(item[key]), 'MM/dd/yyyy hh:mm')}</Td>
              }

              return <Td key={i}>{item[key]}</Td>
            }
            return null
          })}

          <Td>
            {userRole !== 'OPERATOR' && (
              <>
                <ButtonSettings simple>
                  <Settings size={15} onClick={() => history.push(`${editRoute}/${item.id}`)} />
                </ButtonSettings>

                <ButtonDelete
                  simple
                  defaultcursor={disableAdmin.toString()}
                  onClick={() => (disableAdmin ? null : setToggleDelete(item.id))}
                >
                  {disableAdmin ? <Trash size={15} color="transparent" /> : <Trash size={15} />}
                </ButtonDelete>
              </>
            )}
          </Td>
        </tr>
      )
    })
  }

  const handleDelete = (id: string) => {
    if (deleteItem) {
      switch (id) {
        case 'all':
          deleteItem(selectedItems)
          break
        default:
          deleteItem([id] as string[])
      }

      setSelectedItems([]) // remove all selected items
      setToggleDelete('')
    }
  }

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, width: '100%', position: 'absolute' },
  })

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {transitions.map(({ item, key, props }) => {
        return (
          <animated.div style={props} key={key}>
            <Modal toggle={Boolean(toggleDelete)} toggleFunction={setToggleDelete} title="Remove confirmation">
              <div>
                <p>Are you sure you want to remove this data?</p>
                <Button negative onClick={() => handleDelete(toggleDelete)}>
                  Remove
                </Button>
              </div>
            </Modal>
            <DTContainer>
              <DTHead>
                <TitleAndCount>
                  <h2>{title}</h2>
                  <p>({totalItems})</p>
                </TitleAndCount>
                {userRole !== 'OPERATOR' && addNewButton && (
                  <Buttons>
                    {removeAllButton && selectedItems.length > 1 && (
                      <RemoveDataButton onClick={() => setToggleDelete('all')}>{removeAllButton}</RemoveDataButton>
                    )}

                    <AddDataButton onClick={() => history.push(addNewRoute)}>
                      <Plus size={16} />
                      {addNewButton}
                    </AddDataButton>
                  </Buttons>
                )}
              </DTHead>
              {!item && data.length <= 0 ? (
                <animated.div style={props} key={key}>
                  <p>No data found.</p>
                </animated.div>
              ) : (
                <Table>
                  <Thead>
                    <tr>
                      <Th>
                        {userRole !== 'OPERATOR' && addNewButton && (
                          <CheckBox toggle={data.length === selectedItems.length} onClick={() => handleSelect('all')} />
                        )}
                      </Th>
                      {renderHeader}
                      <Th />
                    </tr>
                  </Thead>
                  <Tbody>
                    {item ? (
                      <>
                        {loadingData()}
                        {loadingData()}
                        {loadingData()}
                      </>
                    ) : (
                      renderData()
                    )}
                  </Tbody>
                </Table>
              )}
              <DTFooter>
                <Pagination page={page} totalPages={totalPages} setPage={setPage} />
                {/* <RPPDropDown
              onClick={setRowsPerPage}
              value={rowsPerPage}
              options={['10 Rows', '20 Rows', '50 Rows']}
              label="Show"
              labelLeft
            /> */}
              </DTFooter>
            </DTContainer>
          </animated.div>
        )
      })}
    </div>
  )
}

export default DataTable
