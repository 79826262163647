const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const USERS_API = `${API_BASE_URL}/users`
export const CHECK_USER_EXISTS_API = `${USERS_API}/check-exists`

export const SILO_TYPES_API = `${API_BASE_URL}/silo-types`
export const FLEETS_API = `${API_BASE_URL}/fleets`
export const MODEM_API = `${API_BASE_URL}/silos/modems`
export const CUSTOMERS_API = `${API_BASE_URL}/customers`
export const SILO_API = `${API_BASE_URL}/silos`
