import React from 'react'
import { MetroSpinner } from 'react-spinners-kit'
import { ButtonStyled, WithIcon } from './styles'
import { IconName } from '../../config/types/Icons'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  simple?: boolean
  icon?: IconName
  iconRight?: boolean
  negative?: boolean
  secondary?: boolean
  full?: boolean
}

/**
 * Button
 * @param loading - Add a spinner inside the button
 * @param simple - Remove all button styles
 * @param icon - Icon name, Adds an icon from react-feather on the left side
 * @param iconRight - With the icon property active, adds the icon to its right side
 * @param negative - Add negative Button styles
 * @param secondary - Add secondary Button styles
 * @param full - Makes the button 100% width
 */

const Button = ({
  loading,
  children,
  simple,
  icon,
  iconRight,
  negative,
  secondary,
  full,
  ...otherProps
}: ButtonProps) => {
  const Render = () => {
    if (loading) {
      return <MetroSpinner color="white" size={20} />
    }

    if (icon) {
      const Icon = require('react-feather')[icon]
      return (
        <WithIcon iconRight={iconRight}>
          <Icon size={16} /> {children && children}
        </WithIcon>
      )
    }

    return <>{children}</>
  }
  return (
    <ButtonStyled simple={simple} negative={negative} secondary={secondary} full={full} {...otherProps}>
      <Render />
    </ButtonStyled>
  )
}

export default Button
