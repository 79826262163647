import styled, { css } from 'styled-components'
import { GlobalContainer } from '../../../../../../components/GlobalContainer'
import { LIGHT_GREY, VERY_LIGHT_PINK, PRIMARY } from '../../../../../../config/styles/colours'

type StepProps = {
  active: boolean
  done: boolean
}
type BarProps = {
  done: boolean
}

export const StepBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${GlobalContainer}
  margin-top: 72px;

  > div:nth-of-type(3):before {
    content: 'Create Fleet';
    left: -30px;
  }

  > div:nth-of-type(5):before {
    content: 'Add Silos';
    left: -20px;
  }

  > div:last-of-type:before {
    content: 'Add Users';
    left: -52px;
  }
`

const StepActiveStyles = css`
  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${PRIMARY};
  }
`

export const Step = styled.div<StepProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${({ done }) => (done ? PRIMARY : LIGHT_GREY)};
  position: relative;
  &:before {
    content: 'Customer Name';
    position: absolute;
    width: 120px;
    top: -25px;
    left: 0;
  }

  ${({ active }) => active && StepActiveStyles}
`
export const Bar = styled.div<BarProps>`
  flex: 1;
  height: 4px;
  background-color: ${({ done }) => (done ? PRIMARY : VERY_LIGHT_PINK)};
`
