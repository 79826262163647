import React from 'react'
import { InputSectionContainer, Title, Border, Subtitle } from './styles'

interface InputSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  subtitle?: string
}

/**
 * InputSection
 * @param title - H2 section title
 * @param subtitle - Add a paragraph under the border line;
 */

const InputSection = ({ title, subtitle, children, ...otherProps }: InputSectionProps) => (
  <InputSectionContainer {...otherProps}>
    <Title>{title}</Title>
    <Border />
    <Subtitle>{subtitle}</Subtitle>
    {children}
  </InputSectionContainer>
)

export default InputSection
