import React from 'react'
import { SortableContainer, SortableHandle } from 'react-sortable-hoc'
import DropDown from '../../../../../../components/DropDown'
import { InputGroup } from '../../../../../../components/InputGroup'
import InputText from '../../../../../../components/InputText'
import { RemoveSectionButton, SortSectionButton } from '../../styles'

interface ModemsProps {
  modem: {
    modemId: string
    assignedSilo: string
  }
  handleModemChange: (value: string, index: number, inputName: string) => void
  removeModemFromArray: (index: number) => void
  index: number
  modemList: string[]
  loading?: boolean
  error?: boolean
}

/**
 * InputSection
 * @param modem - Modem object
 * @param handleModemChange - Function change this object in the array
 * @param removeModemFromArray - Function to remove this object from the array
 * @param modemList - Array of strings with all the available modems
 * @param index - Array index
 * @param loading - Value for the inputs
 */

export const DragHandle = SortableHandle(() => <SortSectionButton icon="Move" simple />)
export const SortContainer = SortableContainer(({ children }: any) => {
  return <ul>{children}</ul>
})

const Modems = ({ modem, handleModemChange, index, removeModemFromArray, loading, modemList, error }: ModemsProps) => {
  return (
    <InputGroup key={index}>
      <DragHandle />

      <DropDown
        disabled={loading}
        label="Silo ID (Unit)"
        value={modem && modem.modemId}
        options={modemList}
        onClick={value => handleModemChange(value, index, 'alias')}
        error={error ? 'Please select a valid modem.' : undefined}
        search
      />
      <InputText
        disabled={loading}
        label="Assign Silo ID"
        value={modem && modem.assignedSilo}
        onChange={e => handleModemChange(e.target.value, index, 'siloId')}
      />
      <RemoveSectionButton icon="X" simple onClick={() => removeModemFromArray(index)} />
    </InputGroup>
  )
}

export default Modems
