import React from 'react'
import Container from '../../components/Container'
import DataTable from '../../components/DataTable'
import Toast from '../../components/Toast'
import useFleets from '../../config/hooks/useFleets'
import { Fleet } from '../../config/types'
import { deleteFleets } from '../../services/fleets'

const Feets = () => {
  const {
    fleets,
    setFleets,
    loading,
    setLoading,
    toast,
    setToast,
    page,
    setPage,
    totalPages,
    totalFleets,
    resetData,
  } = useFleets()

  const handleDelete = async (ids: string[]) => {
    try {
      setLoading(true)
      await deleteFleets(ids)

      setFleets(fleets.filter(f => !ids.includes(f.id)))

      if (ids.length > 1) {
        setToast({ value: 'All Fleets selected have been deleted successfully' })
      } else {
        const fleetDeleted = fleets.find(f => f.id === ids[0]) as Fleet
        setToast({ value: `Fleet ${fleetDeleted.fleetId || ''} has been deleted successfully` })
      }
    } catch (error) {
      setToast({ value: error.response.data.error.reason, type: 'error' })
    }

    setLoading(false)
  }

  return (
    <>
      <Toast message={toast} action={setToast} />
      <Container title="Manage Fleets">
        <DataTable
          headerTitles={['Fleet', 'Customer', 'Silos']}
          dataToDisplay={['fleetId', 'customer.name', 'silos']}
          data={fleets}
          title="Fleets"
          addNewButton="Add Fleet"
          addNewRoute="/new-fleet"
          editRoute="/fleets"
          removeAllButton="Remove Selected"
          loading={loading}
          deleteItem={handleDelete}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          totalItems={totalFleets}
          resetSortData={resetData}
        />
      </Container>
    </>
  )
}

export default Feets
