import useWindowSize from 'react-use/lib/useWindowSize'
import React, { useState, useRef, FormEvent } from 'react'

import {
  LoginContainer,
  SiloImage,
  LoginSection,
  Logo,
  Title,
  SubTitle,
  Content,
  CheckEmail,
  TermsConditionsTitle,
  TermsConditionsLink,
} from './styles'

import LOGO from '../../assets/images/logo.png'
import SILO_IMAGE from '../../assets/images/silo_image.jpg'
import InputText from '../../components/InputText'
import Button from '../../components/Button'
import { LIGHT_BLUISH_GREEN, PRIMARY } from '../../config/styles/colours'
import MagicLink from './components/MagicLink'
import { validateEmail } from '../../config/utils/functions'
import { fetchUserExists } from '../../services/user'
import { sendSignInLinkToEmail } from '../../services/firebase/firebaseAuth'

type LoginProps = {
  initializing?: boolean
}

const Login = ({ initializing = false }: LoginProps) => {
  const { height } = useWindowSize()

  const inputRef = useRef<HTMLLabelElement>(null)

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [toggle, setToggle] = useState()
  const [error, setError] = useState()

  const handleLogin = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault()

    setLoading(true)
    setError('')

    if (!validateEmail(email)) {
      setError('Please enter a valid email.')
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
      setLoading(false)
      return
    }

    try {
      // Throw an error is the user's email doesn't exist on the platform
      const checkUser = await fetchUserExists(email)
      if (
        checkUser &&
        typeof checkUser === 'object' &&
        !['SUPER_ADMIN', 'ADMIN', 'OPERATOR'].includes(checkUser.role)
      ) {
        setLoading(false)
        setError('Not enough permissions.')
        return
      }

      sendSignInLinkToEmail(email)
      setToggle(true)

      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email)
    } catch (error) {
      setError(error.message)
    }

    setLoading(false)
  }

  return (
    <LoginContainer>
      <SiloImage src={SILO_IMAGE} style={{ height }} />
      <LoginSection>
        <Content>
          <Logo src={LOGO} />
          {initializing ? (
            <MagicLink />
          ) : (
            <>
              <Title>Login</Title>
              <SubTitle>Enter the email associated with this account and we’ll send a magic link to sign in.</SubTitle>
              <form onSubmit={handleLogin}>
                <InputText
                  labelRef={inputRef}
                  onChange={e => setEmail(e.target.value)}
                  disabled={loading}
                  error={error}
                  label="Email Address"
                  value={email}
                />
                <Button
                  type="submit"
                  loading={loading}
                  full
                  style={{ backgroundColor: toggle ? LIGHT_BLUISH_GREEN : PRIMARY }}
                  disabled={loading || toggle}
                >
                  {toggle ? 'Magic Link Sent' : 'Login'}
                </Button>
              </form>

              {toggle && <CheckEmail>Please check your email inbox for a link to login.</CheckEmail>}

              <div>
                <TermsConditionsTitle>Terms and Conditions</TermsConditionsTitle>
                <p>
                  By accessing the Smart System Tracker Web Portal I agree to the Smart System Tracker{' '}
                  <TermsConditionsLink to="/policies/SS_TOU.DOCX">Terms of Use</TermsConditionsLink> and{' '}
                  <TermsConditionsLink to="/policies/SS_PP.DOCX">Privacy Policy</TermsConditionsLink>.
                </p>
              </div>
            </>
          )}
        </Content>
      </LoginSection>
    </LoginContainer>
  )
}

export default Login
