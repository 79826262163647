import styled from 'styled-components'
import { LIGHT_GREY, PRIMARY } from '../../config/styles/colours'
import { BORDER_RADIUS } from '../../config/styles/constants'
import Button from '../Button'
import { animated } from 'react-spring'

export const CheckBoxContainer = styled(Button)`
  width: 17px;
  height: 17px;
  padding: 0;
  border: 1px solid ${LIGHT_GREY};
  border-radius: ${BORDER_RADIUS}px;
`
export const IconContainer = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${PRIMARY};
  border-radius: 1px;
`
