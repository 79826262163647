import styled from 'styled-components'
import { BLACK, LIGHT_GREY } from '../../config/styles/colours'
import { BORDER_RADIUS } from '../../config/styles/constants'
import { GlobalContainer } from '../GlobalContainer'

type ContainerStyledProps = {
  secondary?: boolean
  titleMargin?: number
}

export const ContainerStyled = styled.div<ContainerStyledProps>`
  ${GlobalContainer}
  display: flex;
  flex-direction: column;
  border: ${({ secondary }) => (secondary ? `1px solid ${LIGHT_GREY}` : 'none')};
  margin-top: 50px;
  padding: 20px;
  border-radius: ${BORDER_RADIUS}px;
`
export const Title = styled.h1<ContainerStyledProps>`
  font-size: 22px;
  font-weight: 400;
  color: ${BLACK};
  margin-bottom: ${({ secondary, titleMargin }) => (secondary ? 12 : titleMargin)}px;
`
