import React from 'react'
import styled, { css } from 'styled-components/macro'
import Button from '../Button'
import { VERY_LIGHT_PINK, BLACK, LIGHT_GREY, DANGER, PRIMARY, COOL_GREY } from '../../config/styles/colours'
import { BORDER_RADIUS } from '../../config/styles/constants'
import { animated } from 'react-spring'

interface SelectedProps {
  toggle: boolean
  error?: string
  search?: boolean
}

export const DropDownContainer = styled.div`
  width: 100%;
`

export const SelectContainer = styled.div`
  display: flex;
`

const InputStyles = css<SelectedProps>`
  display: flex;
  width: 100%;
  min-width: 150px;
  min-height: 30px;
  justify-content: space-between;
  align-items: center;
  color: ${BLACK};
  text-align: left;
  padding: 4px 6px;
  border: 1px solid ${LIGHT_GREY};
  border-radius: ${BORDER_RADIUS}px;
  border-bottom-left-radius: ${({ toggle }) => (toggle ? 0 : BORDER_RADIUS)}px;
  border-bottom-right-radius: ${({ toggle }) => (toggle ? 0 : BORDER_RADIUS)}px;
  background-color: ${VERY_LIGHT_PINK};
  color: ${({ error }) => (error ? DANGER : 'initial')};
`

const SearchStyles = css<SelectedProps>`
  flex: 1;
  width: auto;
  padding: 0 5px;
  min-width: initial;
  border-right: 1px solid ${VERY_LIGHT_PINK};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: ${({ toggle }) => (toggle ? 0 : BORDER_RADIUS)}px;
  &:focus-within {
    border: 1px solid ${({ error }) => (error ? DANGER : PRIMARY)};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${LIGHT_GREY};
    color: ${COOL_GREY};
  }
`
const SearchButtonStyles = css<SelectedProps>`
  display: flex;
  border: 1px solid ${LIGHT_GREY};
  border-left: 1px solid ${VERY_LIGHT_PINK};
  background-color: ${VERY_LIGHT_PINK};
  border-top-right-radius: ${BORDER_RADIUS}px;
  border-bottom-right-radius: ${({ toggle }) => (toggle ? 0 : BORDER_RADIUS)}px;
  &:focus-within {
    border: 1px solid ${({ error }) => (error ? DANGER : PRIMARY)};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${LIGHT_GREY};
    color: ${COOL_GREY};
  }
`

export const InputSearch = styled(({ toggle, error, search, ...props }) => {
  return <input {...props} />
})<SelectedProps>`
  ${InputStyles}
  ${({ search }) => search && SearchStyles}
outline: 0;
`

export const Selected = styled(({ toggle, error, search, ...props }) => {
  return <Button {...props} />
})<SelectedProps>`
  ${({ search }) => (search ? SearchButtonStyles : InputStyles)}
`

export const Relative = styled(animated.div)`
  position: relative;
  width: 100%;
  display: none;
`
export const Absolute = styled(animated.div)`
  position: absolute;
  width: 100%;
  max-height: 149px;
  overflow-x: hidden;
  border: 1px solid ${LIGHT_GREY};
  border-top: 0;
  background-color: ${VERY_LIGHT_PINK};
  > button:last-of-type {
    border-bottom: 0;
  }
`
export const Option = styled(Button)`
  width: 100%;
  text-align: left;
  padding: 10px 12px;
  transition: background-color 0.3s ease-in-out;
  border: 0;
  border-bottom: 1px solid ${LIGHT_GREY};
  &:hover {
    background-color: white;
  }
`
export const Item = styled.p`
  margin: 0;
`
