import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { BLACK, PRIMARY } from '../../config/styles/colours'

type WithIconProps = {
  iconRight?: boolean
}

export const SimpleLinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${BLACK};
  font-size: 12px;
  transition: 0.3s color ease-in-out;
  &:hover {
    color: ${PRIMARY};
  }
`
export const WithIcon = styled.div<WithIconProps>`
  display: flex;
  flex-direction: ${({ iconRight }) => (iconRight ? 'row-reverse' : 'row')};
  justify-content: center;
  align-content: center;
  > svg {
    ${({ iconRight }) => (iconRight ? { marginLeft: 5 } : { marginRight: 5 })};
  }
`
