import React from 'react'
import styled from 'styled-components'
import { ArrowLeftCircle, ArrowRight } from 'react-feather'
import { LIGHT_GREY, VERY_LIGHT_PINK, PRIMARY, BLACK, COOL_GREY } from '../../../../config/styles/colours'
import Button from '../../../Button'

type PageProps = {
  active?: boolean
}

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  > button:first-child {
    margin-left: 0;
  }
  > button:last-child {
    margin-right: 0;
  }
`

export const Page = styled(({ active, ...props }) => {
  return <Button {...props} />
})<PageProps>`
  margin: 0 8px;
  padding: 0;
  transition: 0.3s ease-in-out;
  color: ${({ active }) => (active ? PRIMARY : COOL_GREY)};
  &:hover {
    color: ${PRIMARY};
  }
`

export const ArrowButton = styled(Button)`
  margin: 0 8px;
  padding: 0;
  > svg {
    transition: 0.3s ease-in-out;
    &:hover {
      color: ${PRIMARY};
    }
  }
`
export const ArrowLeft = styled(ArrowLeftCircle)`
  fill: ${VERY_LIGHT_PINK};
  color: ${LIGHT_GREY};
`
export const ArrowR = styled(ArrowRight)`
  color: ${BLACK};
`
