import React from 'react'
import { X } from 'react-feather'
import { useTransition, useSpring } from 'react-spring'

import { Overlay, ModalContent, ModalHeader, Close, ModalTitle } from './styles'

interface ModalProps {
  toggle: boolean
  toggleFunction: React.SetStateAction<any>
  title?: string
  children?: React.ReactNode
}

/**
 * Modal
 * @param toggle - Show/hide the modal
 * @param toggleFunction - Pass the change state function (hook)
 * @param title - Title displayed on the top of the modal
 * @param children - Pass any component as a children
 */

const Modal = ({ toggle, toggleFunction, children, title }: ModalProps) => {
  const fadeOpacity = useTransition(toggle, null, {
    from: { position: 'fixed', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const faceTop = useSpring({ marginTop: toggle ? 0 : -800 })

  return (
    <>
      {fadeOpacity.map(
        ({ item, key, props }) =>
          item && (
            <Overlay key={key} style={props}>
              <ModalContent style={faceTop}>
                {title && (
                  <ModalHeader>
                    <ModalTitle>{title}</ModalTitle>
                    <Close simple onClick={() => toggleFunction(false)}>
                      Close <X size={14} />
                    </Close>
                  </ModalHeader>
                )}
                {children}
              </ModalContent>
            </Overlay>
          ),
      )}
    </>
  )
}

export default Modal
