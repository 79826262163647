import React from 'react'
import Container from '../../components/Container'
import DataTable from '../../components/DataTable'
import Toast from '../../components/Toast'
import useSession from '../../config/hooks/session'
import useSiloTypes from '../../config/hooks/useSiloTypes'
import { SiloType } from '../../config/types'
import { deleteSiloTypes } from '../../services/silosTypes'

const SiloTypes = () => {
  const {
    siloTypes,
    setSiloTypes,
    loading,
    toast,
    setToast,
    setLoading,
    page,
    setPage,
    totalPages,
    totalSiloTypes,
    resetData,
  } = useSiloTypes()
  const user = useSession()

  const handleDelete = async (ids: Array<string>) => {
    try {
      setLoading(true)
      await deleteSiloTypes(ids)

      setSiloTypes(siloTypes.filter(s => !ids.includes(s.id)))

      if (ids.length > 1) {
        setToast({ value: 'All Silo Types selected have been deleted successfully' })
      } else {
        const siloTypeDeleted = siloTypes.find(s => s.id === ids[0]) as SiloType
        setToast({ value: `Silo type with model ${siloTypeDeleted.model} has been deleted successfully` })
      }
    } catch (error) {
      setToast({ value: error.response.data.error.reason, type: 'error' })
    }

    setLoading(false)
  }

  return (
    <>
      <Toast message={toast} action={setToast} />
      <Container title="Manage Silo Type">
        <DataTable
          headerTitles={['Model Name', 'Capacity']}
          dataToDisplay={['model', 'capacity']}
          data={siloTypes}
          title="Models"
          removeAllButton="Remove Selected"
          addNewButton="Add Model"
          addNewRoute="/new-model"
          editRoute="/silo-types"
          deleteItem={handleDelete}
          userRole={user.role}
          loading={loading}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          totalItems={totalSiloTypes}
          resetSortData={resetData}
        />
      </Container>
    </>
  )
}

export default SiloTypes
