import { useState, useEffect } from 'react'
import { useSpring } from 'react-spring'
import { MessageProps } from '../../components/Toast'
import { fetchSingleSiloType, RetrieveSingleSiloType } from '../../services/silosTypes'

const useSingleSiloType = (id: string) => {
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const [modelName, setModelName] = useState('')
  const [maxCapacity, setMaxCapacity] = useState('')
  const [loadingData, setLoadingData] = useState(true)
  const [props, set] = useSpring(() => ({ opacity: 0 }))

  useEffect(() => {
    const getSingleSiloType = async () => {
      if (id) {
        try {
          const response: RetrieveSingleSiloType = await fetchSingleSiloType(id)
          const { model, capacity } = response.data.data
          setMaxCapacity(`${capacity}`)
          setModelName(model)
        } catch (error) {
          if (error.response.status === 400) {
            setToast({
              value: "The silo type you are trying to access, doesn't seem to exist.",
              type: 'error',
              redirect: '/silo-types',
            })
          } else {
            setToast({ value: error.response.data.error.message, type: 'error' })
          }
        }
      }

      set({ opacity: 1 })
      setLoadingData(false)
    }

    getSingleSiloType()
  }, [id, set])

  return { modelName, setModelName, maxCapacity, setMaxCapacity, loadingData, toast, setToast, props }
}

export default useSingleSiloType
