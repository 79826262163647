import { useEffect, useState } from 'react'
import { MessageProps } from '../../components/Toast'
import { fetchFleets, RetrieveAllFleets } from '../../services/fleets'
import { Customer } from '../types'

export type newFleet = {
  id: string
  fleetId: string
  silos?: string[]
  customer?: Customer
  customerId?: string
}

const useFleets = () => {
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const [loading, setLoading] = useState(true)
  const [fleets, setFleets] = useState<newFleet[]>([])
  const [totalFleets, setTotalFleets] = useState()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState()
  // const [itemsPerPage, setItemsPerPage] = useState(15)
  const itemsPerPage = 15
  /* Default: Sort Data */
  const [resetData, setResetData] = useState(false)

  const getSiloTypes = async () => {
    try {
      setLoading(true)
      const response: RetrieveAllFleets = await fetchFleets(itemsPerPage, page === 1 ? 0 : (page - 1) * itemsPerPage)
      const { fleets, total } = response.data.data
      const newFleets: newFleet[] = []
      setTotalFleets(total)
      setTotalPages(Math.ceil(total / itemsPerPage))
      fleets.map(({ silos, ...other }) => {
        const siloList: string[] = []
        if (silos && silos.length > 0) {
          silos
            .sort((a, b) => {
              return a.sortOrder - b.sortOrder
            })
            .map(({ siloId }) => siloList.push(siloId))
        }
        return newFleets.push({ silos: siloList, ...other })
      })

      setFleets(newFleets)
      setResetData(true)
    } catch (error) {
      setToast({ value: error.message, type: 'error' })
    }
    setLoading(false)
    setResetData(false)
  }

  useEffect(() => {
    getSiloTypes()
    // eslint-disable-next-line
  }, [page, itemsPerPage])

  return {
    loading,
    setLoading,
    fleets,
    setFleets,
    toast,
    setToast,
    page,
    setPage,
    totalPages,
    itemsPerPage,
    totalFleets,
    resetData,
  }
}

export default useFleets
