import React from 'react'
import { Link } from 'react-router-dom'

import { DocTitle, DocList, DocUpdated, Intro } from '../../styles'

const Terms = () => {
  return (
    <div>
      <DocTitle>terms of use</DocTitle>

      <p>
        Please read the following Terms of Use (the “Terms”) carefully before using the Smart Sand, Inc (“Smart Sand”,
        “we,” “our,” or “us”) Mobile Application Mine to Wellsite Solutions, our Online Portal (
        <Link to="/">www.sst.smartsand.com</Link>), including all information, tools, features, programs and services
        available in connection with the Mobile Application and Online Portal (together, the “Services”).
      </p>

      <Intro>
        By accessing the Mobile Application or Online Portal, or by using the Services, you are entering into a legal
        contract with us regarding your use of the Services. You agree to be bound by these Terms. If you do not agree
        to these Terms, you should immediately uninstall the Mobile Application and discontinue use of the Services.
      </Intro>

      <DocList listType="decimal" nopadding normal>
        {/* PART 1 */}
        <li>
          <b>Limited License.</b> Smart Sand hereby grants to you a non-transferable, non-exclusive, limited license to
          use and access the Services for your personal and non-commercial use as permitted by these Terms.
        </li>

        {/* PART 2 */}
        <li>
          <b>Convenience and For Informational Purposes.</b> The Services are provided as a convenience and for
          informational purposes only. We do not warrant or represent that: (a) any statement, documents, images,
          graphics, logos, designs, audio, video, or any other information provided from or on the Services
          (collectively, the “Content”) is accurate or complete; (b) the Content, including current information on the
          SmartDepot™ storage equipment, is up-to-date, current, or fit for your purposes; (c) we have any obligation to
          update any Content; (d) the Content is free from technical inaccuracies or programming or typographical
          errors; (e) the Content is free from changes made by a third party; (f) your access to the Services will be
          free from interruptions, errors, computer viruses or other harmful components; and/or (g) any information
          obtained in response to comments or questions asked through the Services is or will be accurate or complete.{' '}
          <i>
            Smart Sand shall not be responsible or liable for your reliance on the Content provided in the Services to
            make any decisions relating to your business or operations.
          </i>
        </li>

        {/* PART 3 */}
        <li>
          <b>Your Use of the Services.</b> The ownership of the Services, Content, and all rights therein are and will
          remain with Smart Sand. You may view, copy, or print a single copy of any page from the Mobile Application or
          Online Portal for personal, non-commercial purposes if you do not remove, modify, or alter any copyright or
          proprietary rights notices that may be present. You may not otherwise use, modify, copy, print, display,
          distribute, publish, or sell any information, images, or other Content from the Services without our express,
          prior written consent.{' '}
        </li>

        {/* PART 4 */}
        <li>
          <b>Restrictions on Your Use.</b> You may not: (i) remove or obscure any copyright, trademark, digital
          watermarks, proprietary legends or other proprietary notices from any portion of the Services; (ii) reproduce,
          modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly
          display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services, or any aspect of
          them, except as expressly permitted by us; (iii) decompile, reverse engineer, jeopardize the correct
          functioning of the Services, or disassemble the Services, or otherwise attempt to derive the source code of
          the software (including the tools, processes, methods, and infrastructure) that enables or underlies the
          Services, except as may be permitted by applicable law; (iv) cause or launch any programs, spiders, robots, or
          scripts or other automatic or manual devices or processes for the purpose of extracting, scraping, indexing,
          surveying, or otherwise data mining any portion of the Services, or unduly burdening or hindering the
          operation and/or functionality of any aspect of the Services; (v) attempt to gain unauthorized access to or
          impair any aspect of the Services, or the related systems, servers, or networks; (vi) use the Services to
          stalk, harass or harm another individual, or in any way that is in violation of any law or regulation; (vii)
          impersonate any person or entity, or otherwise misrepresent your affiliation with any person or entity; or
          (viii) use any portion of the Services or any Content in any manner that may give a false or misleading
          impression, attribution or statement as to us or any other person or entity.{' '}
        </li>

        {/* PART 5 */}
        <li>
          <b>Updates to the Services.</b> We may make changes to the Services, the Content, or these Terms, or stop
          providing the Services and/or the Content at any time and without further notice to you. We will update the
          Services with any changes to these Terms, and you are encouraged to review these Terms frequently (the date of
          the most recent revision to these Terms appears at the end of these Terms). Your continued access to the
          Mobile Application or Online Portal or your continued use of the Services after such posting constitutes your
          consent to be bound by the Terms, as amended.
        </li>

        {/* PART 6 */}
        <li>
          <b>Privacy.</b> By using our Services, you agree to be bound by our Privacy Policy (
          <Link to="/policies/SS_PP.DOCX">https://sst.smartsand.com/policies/SS_PP.DOCX</Link>
          ), incorporated herein by reference. If you do not agree to the Privacy Policy, you may not access or use the
          Services.
        </li>

        {/* PART 7 */}
        <li>
          <b>Submissions and Content.</b> By submitting Content to any part of the Services, you agree that such
          submissions are non-confidential and non-proprietary for all purposes. Any such submission, and the material
          therein, will be deemed and remain the property of Smart Sand. You represent and warrant that the owner of
          such Content has expressly granted us a royalty-free, perpetual, irrevocable, world-wide non-exclusive license
          to use, reproduce, display, modify, publish, edit, translate, distribute, perform, and create derivative works
          from Content in any media or medium, form, or forum known of hereafter developed.
        </li>

        {/* PART 8 */}
        <li>
          <b>Termination.</b> We, in our sole discretion, have the right to terminate, restrict, or suspend your access
          to the Services for any reason, including, but not limited to, violation of these Terms. You agree that any
          termination of your ability to access or use the Services may be done without prior notice. Smart Sand shall
          not be liable to you or any third party for any termination of your access to the Services.
        </li>

        {/* PART 9 */}
        <li>
          <b>Children and Other Age Requirements.</b> The Services are not intended for children under the age of
          eighteen (18) years. You affirm that you are at least eighteen (18) years of age, and are fully able and
          competent to enter into these Terms.
        </li>

        {/* PART 10 */}
        <li>
          <b>Disclaimer of Warranties. </b>
        </li>
        <p>
          YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES AND THE CONTENT ARE
          PROVIDED “AS IS” AND “AS AVAILABLE” FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
          UNLESS THESE WARRANTIES ARE LEGALLY INCAPABLE OF EXCLUSION. NEITHER SMART SAND NOR ANY PERSON ASSOCIATED WITH
          SMART SAND WARRANTS ANY CONTENT ON THE SERVICES WITH RESPECT TO COMPLETENESS, SECURITY, RELIABILITY, QUALITY,
          ACCURACY, OR AVAILABILITY.
        </p>
        <p>
          WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND THAT THE CONTENT OR SERVICES WILL BE AVAILABLE AND REMAIN
          FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS, SUCH AS WORMS OR TROJANS, THAT DEFECTS WILL BE CORRECTED, THAT
          THE SERVICES WILL REMAIN UNINTERRUPTED OR ERROR-FREE, OR THAT ANY SERVICE OR CONTENT OBTAINED THROUGH THE
          SERVICES WILL OTHERWISE MEET YOUR NEEDS AND EXPECTATIONS.{' '}
        </p>
        <p>
          WE DISCLAIM ALL WARRANTIES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTY OF ACCURACY, COMPLETENESS,
          RELIABILITY, MERCHANTABILITY, OR FITNESS FOR A PURPOSE, OR ANY WARRANTY THAT THE SERVICES ARE FREE OF VIRUSES
          OR OTHER HARMFUL ELEMENTS, AND SUCH WARRANTIES ARE EXPRESSLY DISCLAIMED.
        </p>

        {/* PART 11 */}
        <li>
          <b>Limitation of Liability.</b> NEITHER SMART SAND NOR ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUCCESSORS,
          OR ASSIGNS SHALL BE LIABLE FOR ANY LOSS OR DAMAGES (INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
          CONSEQUENTIAL, OR PUNITIVE DAMAGES), OF ANY KIND ARISING OUT OF OR RELATED TO YOUR ACCESS OF, USE OF, OR
          INABILITY TO ACCESS OR USE, OR THE RESULTS OF YOUR USE OF THE SERVICES. THE FOREGOING DOES NOT AFFECT ANY
          LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </li>

        {/* PART 12 */}
        <li>
          <b>Indemnification.</b> You agree to defend, indemnify, and hold harmless Smart Sand and its directors,
          officers, employees, agents, successors and assigns from and against any and all claims, demands, suits,
          proceedings, liabilities, judgments, losses, damages, expenses, and costs (including, but not limited to,
          reasonable attorneys’ fees) assessed or incurred by us, directly or indirectly, with respect to or arising out
          of: (i) your failure to comply with these Terms; (ii) your use of the Services, or (iii) your negligence or
          willful misconduct.
        </li>

        {/* PART 13 */}
        <li>
          <b>Network Access and Devices.</b> You are responsible for obtaining the data network access necessary to use
          the Services. If you are accessing the Services on your mobile device, your network’s data and messaging rates
          and fees may apply. You are responsible for acquiring and updating compatible hardware or devices necessary to
          access and use the Services and any updates thereto. We do not guarantee that the Services, or any portion
          thereof, will function on any particular hardware or devices. In addition, the Services may be subject to
          malfunctions and delays inherent in the use electronic devices.
        </li>

        {/* PART 14 */}
        <li>
          <b>Intellectual Property.</b> The Content of the Services is intellectual property owned, controlled and/or
          licensed by us and/or our affiliates, or intellectual property that we are legally permitted to access and
          use. All applicable intellectual property laws, including copyright laws, protect our rights in and to the
          Content. No portion of the Content may be reproduced in any form or by any means, except as provided elsewhere
          in these Terms or upon the written consent of Smart Sand. We are the owner or authorized licensee of, or are
          otherwise permitted to use, all trademarks, service marks, and logos used and displayed by us on the Services.
          All trademarks and service marks of Smart Sand that may be referred to on the Services are the property of
          Smart Sand or its affiliates. Nothing on the Services should be construed as granting, by implication,
          estoppel, or otherwise, any license or right to use any of our, or our affiliates’, trademarks, service marks,
          logos, or copyrighted materials without our prior written permission.
        </li>

        {/* PART 15 */}
        <li>
          <b>Governing Law; Jurisdiction and Venue.</b> You agree that all matters relating to your access to, or use
          of, the Services will be governed by the laws of the State of Delaware, without regard to any conflict of laws
          principles. You agree and hereby submit to the exclusive personal jurisdiction and venue of the state and
          federal courts located in the State of Delaware with respect to such matters.
        </li>

        {/* PART 16 */}
        <li>
          <b>Local Laws.</b> We make no representation that the Services are appropriate or available for use in
          jurisdictions that are outside the United States. Access to the Services from jurisdictions where such access
          is illegal is prohibited. If you choose to access the Services from other jurisdictions, you do so at your own
          initiative and are responsible for compliance with applicable local laws.
        </li>

        {/* PART 17 */}
        <li>
          <b>Questions.</b>
        </li>
        <p>If you have additional questions or comments of any kind, please contact us at contact@smartsand.com.</p>

        <DocUpdated>Last updated: November 22, 2019</DocUpdated>
      </DocList>
    </div>
  )
}

export default Terms
