import React from 'react'
import styled, { css } from 'styled-components'
import { PRIMARY, DANGER, LIGHT_GREY, PALE_GREY, COOL_GREY, VERY_LIGHT_PINK } from '../../config/styles/colours'
import { BORDER_RADIUS, Z_INDEX } from '../../config/styles/constants'
import Button from '../Button'

interface InputStyledProps {
  error?: string
  full?: boolean
  disabled?: boolean
}
interface ItemContainerProps {
  highlight: boolean
}

const Disabled = css`
  cursor: not-allowed;
  background-color: ${LIGHT_GREY};
  color: ${COOL_GREY};
`

export const InputTagContainer = styled.div<InputStyledProps>`
  display: flex;
  flex-wrap: wrap;
  border-radius: ${BORDER_RADIUS}px;
  background-color: ${PALE_GREY};
  outline: 0;
  border: 1px solid ${LIGHT_GREY};
  color: ${({ error }) => (error ? DANGER : 'initial')};
  width: ${({ full }) => (full ? '100%' : 'auto')};
  &:focus-within {
    border: 1px solid ${({ error }) => (error ? DANGER : PRIMARY)};
  }

  ${({ disabled }) => disabled && Disabled}
`
export const InputTagStyled = styled.input`
  height: 20px;
  margin: 4px;
  background-color: transparent;
  border: 0;
  outline: 0;
`
export const ItemContainer = styled(({ highlight, ...props }) => <Button {...props} />)<ItemContainerProps>`
  display: flex;
  align-items: center;
  background-color: ${({ highlight }) => (highlight ? '#77d1fb' : PRIMARY)};
  padding: 2px 10px;
  height: 20px;
  border-radius: ${BORDER_RADIUS}px;
  margin: 4px;
  transition: background-color 0.3s ease-in-out;
`
export const Item = styled.p`
  margin: 0;
  margin-right: 10px;
  color: white;
`
export const Option = styled(Button)`
  width: 100%;
  padding: 10px 12px;
  background-color: ${VERY_LIGHT_PINK};
  border: 1px solid ${LIGHT_GREY};
  margin-bottom: 2px;
  text-align: left;
`
export const OptionList = styled.ul`
  position: absolute;
  left: 0;
  width: 100%;
  z-index: ${Z_INDEX.modal};
`
export const LoadingDebounce = styled.p`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  right: 0;
  color: ${PRIMARY};
`
