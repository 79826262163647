import styled from 'styled-components/macro'
import { PRIMARY } from '../../config/styles/colours'
import { Link } from 'react-router-dom'

export const LoginContainer = styled.div`
  display: flex;
  height: 100%;
`

export const SiloImage = styled.img`
  flex: 1;
  object-fit: cover;
`
export const LoginSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  max-width: 260px;
  text-align: center;
`

export const Logo = styled.img`
  width: 130px;
  margin-bottom: 24px;
`

export const Title = styled.h1`
  margin-bottom: 24px;
  text-align: center;
`

export const SubTitle = styled.p`
  margin-bottom: 24px;
  text-align: center;
`
export const CheckEmail = styled.p`
  font-size: 11px;
`

export const TermsConditionsTitle = styled.h5`
  margin-bottom: 12px;
`

export const TermsConditionsLink = styled(Link)`
  color: ${PRIMARY};
`
