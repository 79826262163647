import { useState, useEffect } from 'react'
import { MessageProps } from '../../components/Toast'
import { fetchSingleUser } from '../../services/user'
import { useSpring } from 'react-spring'
import { Customer } from '../types'
import { fetchCustomers } from '../../services/customers'

const useSingleUser = (id: string) => {
  // UI
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const [loadingData, setLoadingData] = useState(true)
  const [props, set] = useSpring(() => ({ opacity: 0 }))

  // User details
  const [name, setName] = useState('')
  const [role, setRole] = useState('ADMIN')
  const [email, setEmail] = useState('')

  // Assigned Customers
  const [assignedCustomers, setAssignedCustomers] = useState<Array<{ value: string; label: string }>>([]) // Array of customer who belong to the user
  const [options, setOptions] = useState<Array<{ value: string; label: string }>>([])

  useEffect(() => {
    const getUser = async () => {
      const allCustomers = await fetchCustomers()

      if (id) {
        try {
          const response = await fetchSingleUser(id)

          const { name, role, email, managedCustomers } = response

          setName(name)
          setRole(role)
          setEmail(email)

          // If the single customer has existing customer:
          if (managedCustomers && managedCustomers.length > 0) {
            const assignedCustomers = managedCustomers.map((c: Customer) => {
              return {
                value: c.id,
                label: c.name,
              }
            })
            setAssignedCustomers(assignedCustomers)
          }
        } catch (error) {
          if (error.response.status === 400) {
            setToast({
              value: "The user you are trying to access, doesn't seem to exist.",
              type: 'error',
              redirect: '/smartsand-users',
            })
          } else {
            setToast({ value: error.response.data.error.message, type: 'error' })
          }
        }
      }

      const options = allCustomers.data.data.customers.map((c: Customer) => {
        return {
          value: c.id,
          label: c.name,
        }
      })
      setOptions(options)

      set({ opacity: 1 })
      setLoadingData(false)
    }

    getUser()
  }, [id, set])

  return {
    options,
    assignedCustomers,
    setAssignedCustomers,
    name,
    setName,
    props,
    loadingData,
    role,
    email,
    toast,
    setToast,
    setLoadingData,
    setRole,
    setEmail,
  }
}

export default useSingleUser
