import React from 'react'
import Container from '../../components/Container'
import DataTable from '../../components/DataTable'
import Toast from '../../components/Toast'
import useSilos from '../../config/hooks/useSilos'
import { Silo } from '../../config/types'
import { deleteSilos } from '../../services/silos'

const Silos = () => {
  const {
    loading,
    setToast,
    toast,
    silos,
    setSilos,
    setLoading,
    page,
    setPage,
    totalPages,
    totalSilos,
    resetData,
  } = useSilos()

  const handleDelete = async (ids: string[]) => {
    try {
      setLoading(true)
      await deleteSilos(ids)

      setSilos(silos.filter(s => !ids.includes(s.id)))

      if (ids.length > 1) {
        setToast({ value: 'All Silos selected have been deleted successfully' })
      } else {
        const siloDeleted = silos.find(s => ids.includes(s.id)) as Silo
        setToast({ value: `Silo ${siloDeleted.siloId} has been deleted successfully` })
      }
    } catch (error) {
      setToast({ value: error.response.data.error.reason, type: 'error' })
    }

    setLoading(false)
  }

  return (
    <>
      <Toast message={toast} action={setToast} />
      <Container title="Manage Silos">
        <DataTable
          headerTitles={['Silo ID', 'Silo Model', 'Fleet', 'Customer', 'Location', 'Weight', 'Sand Type']}
          dataToDisplay={[
            'siloId',
            'siloType.model',
            'fleet.fleetId',
            'fleet.customer.customerId',
            'sandData.unitAddress',
            'sandData.currentWeight',
            'sandData.sandType',
          ]}
          data={silos}
          title="Silos"
          addNewButton="Add Silo"
          addNewRoute="/new-silo"
          editRoute="/silos"
          removeAllButton="Remove Selected"
          deleteItem={handleDelete}
          loading={loading}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          totalItems={totalSilos}
          resetSortData={resetData}
        />
      </Container>
    </>
  )
}

export default Silos
