import axios from 'axios'
import { Fleet } from '../config/types'
import { FLEETS_API } from './endpoints'
import { firebaseUser } from './firebase/firebaseAuth'

export type RetrieveAllFleets = { data: { data: { fleets: Fleet[]; total: number } } }
export type RetrieveSingleFleet = {
  data: {
    data: Fleet
  }
}

type FetchFleetParams = {
  fleetId?: string
  skip?: number
  take?: number
}

export const fetchFleets = async (take?: number, skip?: number, unassigned: boolean | undefined = false) => {
  const currentUser = firebaseUser()
  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.get(`${FLEETS_API}${unassigned ? '?unassigned=true' : ''}`, {
      params: {
        take,
        skip,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return results as RetrieveAllFleets
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchFleetsByName = async ({ fleetId, skip, take }: FetchFleetParams) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.get(FLEETS_API, {
      params: {
        fleetId,
        skip,
        take,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return results.data.data.fleets
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchSingleFleet = async (fleetUid: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.get(`${FLEETS_API}/${fleetUid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return results as RetrieveSingleFleet
  }

  throw new Error('User not found. Please sign in again.')
}

export const updateFleet = async (
  fleetUid: string,
  fleetId: string,
  location?: string,
  customerId?: string | null,
  siloIds?: string[],
) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.post(
      `${FLEETS_API}/${fleetUid}`,
      {
        fleetId,
        location,
        customerId,
        siloIds,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const createFleet = async (
  fleetId: string,
  location: string,
  customerId?: string | null,
  siloIds?: string[],
) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    let data = {
      fleetId,
      location,
      siloIds: siloIds && siloIds.length > 0 ? siloIds : undefined, // can't pass empty array to createFleet API
    } as any

    if (customerId) data = { ...data, customerId }

    const token = await currentUser.getIdToken()
    const results = await axios.post(FLEETS_API, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const deleteFleet = async (fleetUid: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.delete(`${FLEETS_API}/${fleetUid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const deleteFleets = async (fleetIds: string[]) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.delete(FLEETS_API, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        fleetIds,
      },
    })
    return results
  }

  throw new Error('User not found. Please sign in again.')
}
