import axios from 'axios'
import { User } from '../config/types'
import { CHECK_USER_EXISTS_API, USERS_API } from './endpoints'
import { firebaseUser } from './firebase/firebaseAuth'

export type UserExists = {
  email: string
  role: 'SUPER_ADMIN' | 'ADMIN' | 'OPERATOR' | 'EXTERNAL_USER'
}

export const fetchUserExists = async (userEmail: string) => {
  const result = await axios.get(CHECK_USER_EXISTS_API, { params: { email: userEmail } })
  if (result.data.data) return result.data.data as UserExists
  throw new Error("This user doesn't exist. Please contact an admin to create an account.")
}

export const fetchUsers = async (take: number, skip: number, role?: string[]) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const result = await axios.get(USERS_API, {
      params: {
        take,
        skip,
        role,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return result
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchSingleUser = async (userUid: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const result = await axios.get(`${USERS_API}/${userUid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return result.data.data as User
  }

  throw new Error('User not found. Please sign in again.')
}

const getRoleApi = (role: string) => {
  let roleApi = 'operators'
  switch (role) {
    case 'EXTERNAL_USER':
      roleApi = 'externals'
      break
    case 'ADMIN':
      roleApi = 'admins'
      break
    case 'SUPER_ADMIN':
      roleApi = 'super-admins'
      break
    default:
  }
  return roleApi
}

/**
 * @param role - user role
 * @param name - user name
 * @param email - user email
 * @param managedCustomerIds - user's assigned customers (only for operator)
 */

export const createUser = async (
  role: string,
  name: string,
  email: string,
  managedCustomerIds?: string[],
  relatingCustomerId?: string,
  accessibleFleetIds?: string[],
) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    let params = { name, email } as {
      name: string
      email: string
      managedCustomerIds?: string[]
      relatingCustomerId?: string
      accessibleFleetIds?: string[]
    }

    switch (role) {
      case 'OPERATOR':
        params = {
          ...params,
          managedCustomerIds: managedCustomerIds && managedCustomerIds.length === 0 ? undefined : managedCustomerIds,
        }
        break
      case 'EXTERNAL_USER':
        params = { ...params, relatingCustomerId, accessibleFleetIds }
        break
      default: // no default
    }

    const token = await currentUser.getIdToken()
    const result = await axios.post(`${USERS_API}/${getRoleApi(role)}`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return result
  }

  throw new Error('User not found. Please sign in again.')
}

/**
 * @param userUid - unique ID
 * @param role - user role
 * @param name - user name
 * @param email - user email
 * @param managedCustomerIds - user's assigned customers (only for operator)
 */
export const updateUser = async (
  userUid: string,
  previousRole: string,
  role?: string,
  name?: string,
  email?: string,
  managedCustomerIds?: string[],
  accessibleFleetIds?: string[],
) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    let params
    if (previousRole === 'EXTERNAL_USER') {
      params = { name, email, accessibleFleetIds }
    } else {
      params = { role, name, email, managedCustomerIds }
    }
    const token = await currentUser.getIdToken()
    const result = await axios.post(`${USERS_API}/${getRoleApi(previousRole)}/${userUid}`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return result
  }

  throw new Error('User not found. Please sign in again.')
}

export const deleteUser = async (userUid: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const result = await axios.delete(`${USERS_API}/${userUid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return result
  }

  throw new Error('User not found. Please sign in again.')
}

export const deleteUsers = async (userIds: string[]) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = axios.delete(USERS_API, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        userIds,
      },
    })

    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const checkIfUserExist = async (email: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const result = await axios.get(`${USERS_API}/check-exists`, {
      params: {
        email,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return result.data.data as {
      email: string
      role: string
      id: string
    }
  }

  throw new Error('User not found. Please sign in again.')
}
