import React from 'react'
import { SimpleLinkStyled, WithIcon } from './styles'
import { IconName } from '../../config/types/Icons'
import { LinkProps } from 'react-router-dom'

interface SimpleLinkProps extends LinkProps {
  icon?: IconName
  iconRight?: boolean
}

/**
 * SimpleLink
 * @param icon - Icon name, Adds an icon from react-feather on the left side
 * @param iconRight - With the icon property active, adds the icon to its right side
 */

const SimpleLink = ({ icon, iconRight, children, ...otherProps }: SimpleLinkProps) => {
  const Render = () => {
    if (icon) {
      const Icon = require('react-feather')[icon]
      return (
        <WithIcon iconRight={iconRight}>
          <Icon size={12} /> {children}
        </WithIcon>
      )
    }

    return <>{children}</>
  }
  return (
    <SimpleLinkStyled {...otherProps}>
      <Render />
    </SimpleLinkStyled>
  )
}

export default SimpleLink
