import { useState, useEffect } from 'react'
import { SiloType, Modems } from '../types'
import { useSpring } from 'react-spring'
import { MessageProps } from '../../components/Toast'
import { fetchSingleSilo, fetchModems, SingleSiloUpdateType } from '../../services/silos'
import { fetchSingleFleet } from '../../services/fleets'
import { fetchSiloTypes } from '../../services/silosTypes'

type ReactSelectType = {
  value: string
  label: string
}

type SiloDetailsType = {
  currentWeight?: string
  latitude?: number
  longitude?: number
  blender?: string
  unitAddress?: string
  sandType?: string
  thresholdAmber?: number
  thresholdRed?: number
}

const useSingleSilo = (id: string) => {
  // UI
  const [props, set] = useSpring(() => ({ opacity: 0 }))
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const [loadingData, setLoadingData] = useState(true)
  const [loading, setLoading] = useState(false) // Search customer

  const [fleet, setFleet] = useState<ReactSelectType>({
    value: '',
    label: '',
  }) // Dropdown Model

  const [currentModel, setCurrentModel] = useState('')
  const [models, setModels] = useState<Array<string>>([])
  const [modelIds, setModelIds] = useState<Array<string>>([]) // Dropdown Modem

  const [currentModem, setCurrentModem] = useState('')
  const [modems, setModems] = useState<Array<Modems>>([]) // Modem Ids and unitIDS

  const [silo, setSilo] = useState<{
    siloId: string
    id: string
  }>({
    id: '',
    siloId: '',
  }) // Silo details

  const [siloDetails, setSiloDetails] = useState<SiloDetailsType | undefined>(undefined) // Silo to update

  const [siloUpdated, setSiloUpdated] = useState<SingleSiloUpdateType>({})

  const dependencies: Array<any> = id ? [id, set] : [id, set, loading]

  useEffect(
    () => {
      const getFleet = async (uid: string) => {
        const results = await fetchSingleFleet(uid)
        const { id, fleetId } = results.data.data
        return { value: id, label: fleetId } as ReactSelectType
      }

      const getModels = async () => {
        const response = await fetchSiloTypes()
        const { siloTypes } = response.data.data

        const models = siloTypes.map((st: SiloType) => st.model) as Array<string>
        const modelIds = siloTypes.map((st: SiloType) => st.id) as Array<string>

        return { models, modelIds }
      }

      const getModems = async () => {
        const response = await fetchModems()
        const modems = response.data.data as Array<Modems>

        return { modems }
      }

      const getSilos = async () => {
        try {
          const { models, modelIds } = await getModels()
          const { modems } = await getModems()

          if (id) {
            const response = await fetchSingleSilo(id)
            const silo = response.data.data

            if (silo.fleetId) {
              const fleet = await getFleet(silo.fleetId)
              setFleet(fleet)
            }

            const model = silo.siloType ? silo.siloType.id : '' // const modem = silo.positionData ? silo.positionData.unitID : ''
            const mdmid = silo.positionData ? silo.positionData.mdmid : ''

            setCurrentModel(model)
            setCurrentModem(mdmid)

            const { siloId, sandData, positionData, thresholdAmber, thresholdRed } = silo

            setSilo({
              siloId,
              id: silo.id,
            })

            const position = positionData || {}
            const sand = sandData || {}

            setSiloDetails({
              thresholdAmber,
              thresholdRed,
              ...position,
              ...sand,
            })
          }

          setModels(models)
          setModelIds(modelIds)

          setModems(modems)
        } catch (error) {
          if (error.response.status === 400) {
            setToast({
              value: "The silo you are trying to access, doesn't seem to exist.",
              type: 'error',
              redirect: '/silos',
            })
          } else {
            setToast({ value: error.response.data.error.message, type: 'error' })
          }
        }

        set({ opacity: 1 })
        setLoadingData(false)
      }

      getSilos()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies, // refresh form with updated list of modems unassigned after added a silo
  )

  return {
    loading,
    setLoading,
    siloDetails,
    setSiloDetails,
    loadingData,
    setSilo,
    silo,
    toast,
    setToast,
    props,
    models,
    modelIds,
    currentModel,
    setCurrentModel,
    modems,
    currentModem,
    setCurrentModem,
    fleet,
    setFleet,
    siloUpdated,
    setSiloUpdated,
  }
}

export default useSingleSilo
