import React from 'react'
import { PaginationContainer, Page, ArrowLeft, ArrowButton, ArrowR } from './styles'

interface PaginationProps {
  page?: number
  totalPages?: number
  setPage?: React.Dispatch<React.SetStateAction<number>>
}

const Pagination = ({ page, totalPages, setPage }: PaginationProps) => {
  if (!page || !totalPages || !setPage) {
    return <></>
  }

  const renderPages = () => {
    const pages = []
    for (let i = 1; i < totalPages + 1; i += 1) {
      pages.push(
        <Page key={i} active={page === i} onClick={() => setPage(i)} simple>
          {i}
        </Page>,
      )
    }
    return pages
  }

  return (
    <PaginationContainer>
      {page > 1 && (
        <ArrowButton simple onClick={() => setPage(page - 1)}>
          <ArrowLeft />
        </ArrowButton>
      )}
      {totalPages > 1 && renderPages()}

      {totalPages > 1 && totalPages !== page && (
        <ArrowButton simple onClick={() => setPage(page + 1)}>
          <ArrowR size={15} />
        </ArrowButton>
      )}
    </PaginationContainer>
  )
}

export default Pagination
