import { useEffect, useState } from 'react'
import { MessageProps } from '../../components/Toast'
import { fetchCustomers } from '../../services/customers'
import { Customer } from '../types'

const useCustomers = () => {
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const [loading, setLoading] = useState(true)
  const [customers, setCustomers] = useState<Customer[]>([])
  const [totalCustomers, setTotalCustomers] = useState()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState()
  // const [itemsPerPage, setItemsPerPage] = useState(15)
  const itemsPerPage = 15
  /* Default: Sort Data */
  const [resetData, setResetData] = useState(false)

  useEffect(() => {
    const getSiloTypes = async () => {
      try {
        setLoading(true)
        const response = await fetchCustomers({
          take: itemsPerPage,
          skip: page === 1 ? 0 : (page - 1) * itemsPerPage,
        })
        const { customers, total } = response.data.data
        setTotalCustomers(total)
        setTotalPages(Math.ceil(total / itemsPerPage))
        setCustomers(customers)
        setResetData(true)
      } catch (error) {
        setToast({ value: error.message, type: 'error' })
      }
      setLoading(false)
      setResetData(false)
    }

    getSiloTypes()
  }, [page, itemsPerPage])

  return {
    loading,
    setCustomers,
    customers,
    toast,
    setToast,
    setLoading,
    page,
    setPage,
    totalPages,
    itemsPerPage,
    totalCustomers,
    resetData,
  }
}

export default useCustomers
