import React from 'react'
import Container from '../../components/Container'
import DataTable from '../../components/DataTable'
import Toast from '../../components/Toast'
import useUsers from '../../config/hooks/useUsers'
import { User } from '../../config/types'
import { deleteUsers } from '../../services/user'

const SmartSandUsers = () => {
  const {
    loading,
    users,
    setUsers,
    toast,
    setToast,
    setLoading,
    page,
    setPage,
    totalPages,
    totalUsers,
    resetData,
  } = useUsers()

  const handleDelete = async (ids: string[]) => {
    try {
      setLoading(true)
      await deleteUsers(ids)

      setUsers(users.filter(u => !ids.includes(u.id)))

      if (ids.length > 1) {
        setToast({ value: 'All Users selected have been deleted successfully' })
      } else {
        const userDeleted = users.find(u => u.id === ids[0]) as User
        setToast({ value: `User ${userDeleted.name} has been deleted successfully` })
      }
    } catch (error) {
      setToast({ value: error.response.data.error.reason, type: 'error' })
    }

    setLoading(false)
  }

  return (
    <>
      <Toast message={toast} action={setToast} />
      <Container title="Manage Users">
        <DataTable
          disableSuperAdmin
          headerTitles={['User Name', 'User email', 'Permissions', 'Customers']}
          dataToDisplay={['name', 'email', 'role', 'managedCustomersNames']}
          data={users}
          title="Smart Sand Users"
          addNewButton="Add User"
          addNewRoute="/new-smartsand-user"
          editRoute="/smartsand-users"
          deleteItem={handleDelete}
          removeAllButton="Remove Selected"
          loading={loading}
          mapValues={{
            key: 'role',
            ids: ['ADMIN', 'SUPER_ADMIN', 'OPERATOR', 'EXTERNAL_USER'],
            values: ['Admin', 'Super Admin', 'Operator', 'External User'],
          }}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          totalItems={totalUsers}
          resetSortData={resetData}
        />
      </Container>
    </>
  )
}

export default SmartSandUsers
