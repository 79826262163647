import styled from 'styled-components'
import { animated } from 'react-spring'
import { DANGER, PRIMARY } from '../../config/styles/colours'
import { Z_INDEX } from '../../config/styles/constants'

type ToastContainerProps = {
  type: 'success' | 'error' | 'info'
}

const selectMessageBackground = (type: 'success' | 'error' | 'info') => {
  switch (type) {
    case 'error':
      return DANGER
    case 'info':
      return PRIMARY

    default:
      return PRIMARY
  }
}

export const ToastContainer = styled(animated.div)`
  display: flex;
  align-items: center;
  position: fixed;
  top: 12px;
  right: 12px;
  width: 100%;
  background-color: ${({ type }: ToastContainerProps) => selectMessageBackground(type)};
  border-bottom: 1px solid ${({ type }: ToastContainerProps) => selectMessageBackground(type)};
  padding: 20px;
  z-index: ${Z_INDEX.modal};
  width: 300px;
`
export const Message = styled.div`
  color: white;
  margin-left: 12px;
`
export const Timer = styled(animated.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: white;
`
