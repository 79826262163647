import { useEffect, useState } from 'react'
import { MessageProps } from '../../components/Toast'
import { fetchCustomerExternalUsers, fetchSingleCustomer } from '../../services/customers'
import { Customer, CustomerUsers } from '../types'

const useCustomerUsers = (customerUid: string) => {
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const [loading, setLoading] = useState(true)
  const [customer, setCustomer] = useState<Customer>()
  const [users, setUsers] = useState<CustomerUsers[]>([])
  const [totalUsers, setTotalUsers] = useState()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState()
  const [itemsPerPage] = useState(15)
  /* Default: Sort Data */
  const [resetData, setResetData] = useState(false)

  useEffect(() => {
    const getSiloTypes = async () => {
      try {
        // * Get Customer with url id
        setLoading(true)
        const customerResult = await fetchSingleCustomer(customerUid)
        const { data: customerRes } = customerResult.data
        setCustomer(customerRes)

        // * Get all External users related to this customer
        const userRes = await fetchCustomerExternalUsers(
          itemsPerPage,
          page === 1 ? 0 : (page - 1) * itemsPerPage,
          customerUid,
        )

        const { users, total } = userRes.data
        setTotalUsers(total)
        setTotalPages(Math.ceil(total / itemsPerPage))
        const parsedUser: CustomerUsers[] = []
        users.map(user => {
          const { accessibleFleets, ...rest } = user
          const userFleets =
            accessibleFleets && accessibleFleets.length > 0
              ? accessibleFleets.map(fleet => fleet.fleetId)
              : 'All Fleets'

          parsedUser.push({
            accessibleFleets: userFleets,
            ...rest,
          })
          return null
        })

        setUsers(parsedUser)
        setResetData(true)
      } catch (error) {
        setToast({ value: error.message, type: 'error' })
      }
      setLoading(false)
      setResetData(false)
    }

    getSiloTypes()
  }, [page, customerUid, itemsPerPage])

  return {
    users,
    setUsers,
    loading,
    customer,
    toast,
    setToast,
    setLoading,
    page,
    setPage,
    totalPages,
    itemsPerPage,
    totalUsers,
    resetData,
  }
}

export default useCustomerUsers
