import styled from 'styled-components'
import { GlobalContainer } from '../../../../components/GlobalContainer'
import SimpleLink from '../../../../components/SimpleLink'

export const BackTo = styled(SimpleLink)`
  align-self: flex-start;
  font-weight: bold;
`

export const AddEditFooter = styled.div`
  ${GlobalContainer}
  display: flex;
  margin-top: 12px;
  > button:first-of-type {
    margin-right: 12px;
  }
`
export const CheckBoxContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  > p {
    margin-bottom: 0;
    margin-left: 12px;
    align-self: flex-end;
  }
`
export const DivBlock = styled.div`
  display: block;
`
