import React from 'react'
import { Router, Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom'
import NotFound from '../screens/NotFound'
import Fleets from '../screens/Fleets'
import Login from '../screens/Login'
import history from '../config/router'
import NavBar from '../components/NavBar'
import FleetAddEdit from '../screens/Fleets/screens/FleetAddEdit'
import SiloTypes from '../screens/SiloTypes'
import SiloTypesAddEdit from '../screens/SiloTypes/screens/SiloTypesAddEdit'
import Silos from '../screens/Silos'
import SilosAddEdit from '../screens/Silos/screens/SilosAddEdit'
import SmartSandUsers from '../screens/SmartSandUsers'
import UsersAddEdit from '../screens/SmartSandUsers/screens/UsersAddEdit'
import Customers from '../screens/Customers'
import AddCustomer from '../screens/Customers/screens/AddCustomer'
import CustomerUsers from '../screens/CustomerUsers'
import CustomersUsersAddEdit from '../screens/CustomerUsers/screens/CustomersUsersAddEdit'
import useAuth from '../config/hooks/useAuth'
import Toast from '../components/Toast'
import userContext from '../config/hooks/userContext'
import Policies from '../screens/Policies'

const Routes = () => {
  const { loading, user, toast, setToast } = useAuth()

  return (
    <userContext.Provider
      value={{
        user,
        loading,
      }}
    >
      <Router history={history}>
        <Toast message={toast} action={setToast} />
        {user && <NavBar />}
        <Switch>
          <Route exact path="/" render={() => (user ? <Redirect to="/fleets" /> : <Login initializing={loading} />)} />
          {user && (
            <>
              <Route path="/new-fleet" component={FleetAddEdit} />
              <Route path="/fleets/:id" component={FleetAddEdit} />
              <Route path="/fleets" component={Fleets} exact />

              <Route path="/new-model" component={SiloTypesAddEdit} />
              <Route path="/silo-types/:id" component={SiloTypesAddEdit} />
              <Route path="/silo-types" component={SiloTypes} exact />

              <Route path="/new-silo" component={SilosAddEdit} />
              <Route path="/silos/:id" component={SilosAddEdit} />
              <Route path="/silos" component={Silos} exact />

              <Route path="/new-smartsand-user" component={UsersAddEdit} />
              <Route path="/smartsand-users/:id" component={UsersAddEdit} />
              <Route path="/smartsand-users" component={SmartSandUsers} exact />

              <Route path="/new-customer" component={AddCustomer} />
              <Route path="/customers/:id" component={CustomerUsers} />
              <Route path="/customers" component={Customers} exact />

              <Route path="/customer-user/:id" component={CustomersUsersAddEdit} />
              <Route path="/new-customer-user/:customerUid" component={CustomersUsersAddEdit} />
            </>
          )}

          <Route
            path="/policies/:doc"
            component={({ location: { pathname } }: RouteComponentProps) => <Policies {...{ pathname }} />}
          />

          {!loading && <Route component={NotFound} />}
        </Switch>
      </Router>
    </userContext.Provider>
  )
}

export default Routes
