import React from 'react'
import { SpinnerContainer, SquareSpinner, InsideAnimation, LoadingMessage } from './styles'

interface SpinnerProps {
  message?: string
}

const Spinner = ({ message }: SpinnerProps) => {
  return (
    <SpinnerContainer>
      <SquareSpinner>
        <InsideAnimation />
      </SquareSpinner>
      {message && <LoadingMessage>{message}</LoadingMessage>}
    </SpinnerContainer>
  )
}

export default Spinner
