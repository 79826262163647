import styled from 'styled-components'
import { PRIMARY, TIN } from '../../config/styles/colours'
import { animated } from 'react-spring'

export const InputSectionContainer = styled(animated.section)`
  margin-top: 30px;
`
export const Title = styled.h2`
  font-size: 13px;
  font-weight: 300;

  margin-bottom: 5px;
  color: ${TIN};
`
export const Border = styled.div`
  width: 100%;
  max-width: 300px;
  height: 1px;
  background-color: ${PRIMARY};
  margin-bottom: 5px;
`
export const Subtitle = styled.p`
  font-size: 12px;
  margin-bottom: 30px;
`
