import axios from 'axios'
import { MODEM_API } from './endpoints'
import { firebaseUser } from './firebase/firebaseAuth'
import { SiloType, SiloSandData } from '../config/types'

export type RetrieveAllModems = {
  data: {
    data: [
      {
        unitID: number
        mdmid: string
        alias: string
        silo?: {
          id: string
          siloId: string
          thresholdAmber: number
          thresholdRed: number
          status: number
          fleetId: string
          siloType?: SiloType
          sandData?: SiloSandData
        }
      },
    ]
  }
}

export const fetchAllModems = async () => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.get(MODEM_API, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return results as RetrieveAllModems
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchAllAssignedModems = async () => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.get(`${MODEM_API}?unassigned=false`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return results as RetrieveAllModems
  }

  throw new Error('User not found. Please sign in again.')
}
