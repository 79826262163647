import { useEffect, useState } from 'react'
import { useSpring } from 'react-spring'
import { MessageProps } from '../../components/Toast'
import { fetchCustomersFleets } from '../../services/customers'
import { fetchSingleUser } from '../../services/user'

interface UseSingleCustomerUserProps {
  userUid?: string
  customerUid?: string
}

const useSingleCustomerUser = ({ userUid, customerUid: cUid }: UseSingleCustomerUserProps) => {
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const [email, setEmail] = useState('')
  const [oldEmail, setOldEmail] = useState('')
  const [userName, setUserName] = useState('')
  const [customerUid, setCustomerUid] = useState('')
  const [assignedFleets, setAssignedFleets] = useState<string[]>([])
  const [customerFleets, setCustomerFleets] = useState<{ fleetUid: string; fleetId: string }[]>([])
  const [checked, setChecked] = useState(false)
  const [loadingData, setLoadingData] = useState(true)
  const [props, set] = useSpring(() => ({ opacity: 0 }))

  useEffect(() => {
    const getSingleUser = async () => {
      const getCustomerFleets = async (customerUid: string) => {
        const customerResult = await fetchCustomersFleets(customerUid)
        const fleets = customerResult.map(fleet => ({ fleetUid: fleet.id, fleetId: fleet.fleetId }))
        setCustomerFleets(fleets)
        setCustomerUid(customerUid)
      }

      if (cUid) {
        await getCustomerFleets(cUid)
      }

      if (userUid) {
        const data = await fetchSingleUser(userUid)
        setEmail(data.email)
        setOldEmail(data.email)
        setUserName(data.name)

        if (data.accessibleFleets && data.accessibleFleets.length > 0) {
          const fleetIds = data.accessibleFleets.map(fleet => fleet.fleetId)
          setAssignedFleets(fleetIds)
        }

        if (!cUid && data.relatingCustomerId) {
          await getCustomerFleets(data.relatingCustomerId)
        }

        if (!data.accessibleFleets || data.accessibleFleets.length === 0) setChecked(true)
      }

      set({ opacity: 1 })
      setLoadingData(false)
    }

    getSingleUser()
  }, [set, setLoadingData, cUid, userUid])

  return {
    oldEmail,
    email,
    setEmail,
    customerFleets,
    assignedFleets,
    setAssignedFleets,
    checked,
    setChecked,
    userName,
    setUserName,
    customerUid,
    loadingData,
    toast,
    setToast,
    props,
  }
}

export default useSingleCustomerUser
