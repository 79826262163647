import { useEffect, useState } from 'react'
import { MessageProps } from '../../components/Toast'
import { fetchSiloTypes, RetrieveAllSiloTypes } from '../../services/silosTypes'
import { SiloType } from '../types'

const useSiloTypes = () => {
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const [loading, setLoading] = useState(true)
  const [siloTypes, setSiloTypes] = useState<SiloType[]>([])
  const [totalSiloTypes, setTotalSiloTypes] = useState()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState()
  // const [itemsPerPage, setItemsPerPage] = useState(15)
  const itemsPerPage = 15
  /* Default: Sort Data */
  const [resetData, setResetData] = useState(false)

  useEffect(() => {
    const getSiloTypes = async () => {
      try {
        setLoading(true)
        const response: RetrieveAllSiloTypes = await fetchSiloTypes(
          itemsPerPage,
          page === 1 ? 0 : (page - 1) * itemsPerPage,
        )
        const { siloTypes, total } = response.data.data
        setTotalSiloTypes(total)
        setTotalPages(Math.ceil(total / itemsPerPage))
        const newSiloTypes = siloTypes.map(st => {
          return { ...st, capacity: `${st.capacity} ft³` }
        })
        setSiloTypes(newSiloTypes)
        setResetData(true)
      } catch (error) {
        setToast({ value: error.message, type: 'error' })
      }
      setLoading(false)
      setResetData(false)
    }

    getSiloTypes()
  }, [page, itemsPerPage])

  return {
    loading,
    siloTypes,
    setSiloTypes,
    toast,
    setToast,
    setLoading,
    page,
    setPage,
    totalPages,
    itemsPerPage,
    totalSiloTypes,
    resetData,
  }
}

export default useSiloTypes
