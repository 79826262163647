import styled from 'styled-components'

export const LoadingContainer = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
  > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`
