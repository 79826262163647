import React, { useState, useRef } from 'react'
import { animated } from 'react-spring'
import { match } from 'react-router'
import Container from '../../../../components/Container'
import InputSection from '../../../../components/InputSection'
import InputText from '../../../../components/InputText'
import { AddEditFooter, BackTo } from './styles'
import Button from '../../../../components/Button'
import Toast from '../../../../components/Toast'
import Spinner from '../../../../components/Spinner'
import { InputGroup } from '../../../../components/InputGroup'
import useSingleSiloType from '../../../../config/hooks/useSingleSiloType'
import { updateSingleSiloType, createSingleSiloType } from '../../../../services/silosTypes'

interface SiloTypesAddEditProps {
  match: match<{ id: string }>
}

const SiloTypesAddEdit = ({ match }: SiloTypesAddEditProps) => {
  const { id } = match.params

  const {
    modelName,
    setModelName,
    maxCapacity,
    setMaxCapacity,
    loadingData,
    props,
    toast,
    setToast,
  } = useSingleSiloType(id)

  const [loading, setLoading] = useState(false)

  const modelNameRef = useRef<HTMLLabelElement>(null)
  const maxCapacityRef = useRef<HTMLLabelElement>(null)

  const [modelNameError, setModelNameError] = useState('')
  const [maxCapacityError, setMaxCapacityError] = useState('')

  const handleUpdate = async () => {
    manageErrors()

    const parsedCapacity = getParsedCapacity()
    if (parsedCapacity) {
      setLoading(true)
      try {
        const data = { capacity: parsedCapacity, model: modelName }
        const response = await updateSingleSiloType(id, data)

        setToast({ value: response.data.message })
      } catch (error) {
        setToast({ value: error.message, type: 'error' })
      }

      setLoading(false)
    }
  }

  const manageErrors = () => {
    if (modelNameError || maxCapacityError) {
      setModelNameError('')
      setMaxCapacityError('')
    }

    if (!modelName) {
      setModelNameError('This field is required, please add a model name for this fleet.')
      if (modelNameRef && modelNameRef.current) {
        modelNameRef.current.focus()
      }
      return
    }

    if (!maxCapacity) {
      setMaxCapacityError('This field is required, please add a max capacity for this fleet.')
      if (maxCapacityRef && maxCapacityRef.current) {
        maxCapacityRef.current.focus()
      }
      return
    }
  }

  const getParsedCapacity = () => {
    const parsedCapacity = parseFloat(maxCapacity)
    if (!Number.isInteger(parsedCapacity) || parsedCapacity <= 0) {
      setMaxCapacityError('Max capacity needs to be a valid integer greater than 0.')
      return null
    }
    return parsedCapacity
  }

  const handleCreate = async () => {
    manageErrors()
    const parsedCapacity = getParsedCapacity()
    if (parsedCapacity) {
      setLoading(true)
      try {
        const response = await createSingleSiloType(parsedCapacity, modelName)

        // * Reset to inital state:
        setModelName('')
        setMaxCapacity('')
        setToast({ value: response.data.message })
      } catch (error) {
        setToast({ value: error.message, type: 'error' })
      }
      setLoading(false)
    }
  }

  if (loadingData) {
    return <Spinner message="Fetching Data..." />
  }

  return (
    <>
      <Toast message={toast} action={setToast} />
      <animated.div style={props}>
        <Container title={id ? 'Update Silo Type' : 'Add New Silo Type'} secondary>
          <BackTo to="/silo-types" icon="ArrowLeft">
            Back to Silo Types
          </BackTo>
          <InputSection title="Model Details">
            <InputGroup>
              <InputText
                disabled={loading}
                label="Model Name"
                value={modelName}
                onChange={e => setModelName(e.target.value)}
                required
                error={modelNameError}
                labelRef={modelNameRef}
              />
              <InputText
                type="number"
                step="1"
                min="1"
                disabled={loading}
                value={maxCapacity}
                onChange={e => setMaxCapacity(e.target.value)}
                required
                label="Model Max Capacity"
                labelRef={maxCapacityRef}
                error={maxCapacityError}
                group="ft³"
              />
            </InputGroup>
          </InputSection>
        </Container>
        <AddEditFooter>
          <Button onClick={id ? handleUpdate : handleCreate} loading={loading}>
            {id ? 'Update' : 'Create'} Silo Type
          </Button>
        </AddEditFooter>
      </animated.div>
    </>
  )
}

export default SiloTypesAddEdit
