import axios from 'axios'
import { CUSTOMERS_API } from './endpoints'
import { firebaseUser } from './firebase/firebaseAuth'
import { Customer, Fleet, User } from '../config/types'

type FetchCustomersParams = {
  name?: string
  skip?: number
  take?: number
}

export const fetchCustomers = async (params?: FetchCustomersParams) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const result = await axios.get(`${CUSTOMERS_API}`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return result as { data: { data: { customers: Customer[]; total: number } } }
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchSingleCustomer = async (customerUid: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const result = await axios.get(`${CUSTOMERS_API}/${customerUid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return result
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchCustomersByIds = async (ids: string[]) => {
  const results = await Promise.all(
    ids.map(async id => {
      const result = await fetchSingleCustomer(id)
      return result.data as Customer
    }),
  )
  return results as Array<Customer>
}

export const createCustomer = async (
  name: string,
  customerId: string,
  fleetIds?: string[],
  siloIds?: string[],
  relatingExternalUserIds?: string[],
  managingOperatorIds?: string[],
) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const result = await axios.post(
      `${CUSTOMERS_API}`,
      {
        name,
        customerId,
        fleetIds,
        siloIds,
        relatingExternalUserIds,
        managingOperatorIds,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return result
  }

  throw new Error('User not found. Please sign in again.')
}

export const deleteCustomer = async (customerUid: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const result = await axios.delete(`${CUSTOMERS_API}/${customerUid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return result
  }

  throw new Error('User not found. Please sign in again.')
}

export const deleteCustomers = async (customerIds: string[]) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const result = await axios.delete(CUSTOMERS_API, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        customerIds,
      },
    })
    return result
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchCustomersFleets = async (customerUid: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const result = await axios.get(`${CUSTOMERS_API}/${customerUid}/fleets`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return result.data.data as Array<Fleet>
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchCustomerExternalUsers = async (take: number, skip: number, customerUid: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const result = await axios.get(`${CUSTOMERS_API}/${customerUid}/externals`, {
      params: {
        take,
        skip,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return result.data as { data: { users: User[]; total: number } }
  }

  throw new Error('User not found. Please sign in again.')
}
