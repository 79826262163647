export const PRIMARY = '#4FB5E5'
export const DARK_SKY_BLUE = '#45ABE8'
export const PRIMARY_HOVER = '#9ECEE5'
export const PALE_SKY_BLUE = '#C7ECFD'
export const DANGER = '#DB4C45'
export const DANGER_HOVER = '#DB625C'
export const REMOVE_ALL = '#ec3c3c'
export const SUCCESS = '#6BE99E'
export const LIGHT_BLUISH_GREEN = '#6CE99F'
export const WARNING = '#E5A63A'
export const BLACK = '#363a40'
export const COOL_GREY = '#9d9fa3'
export const TIN = '#7C7B86'
export const LIGHT_GREY = '#E5E5E5'
export const PALE_GREY = '#F9FAFB'
export const VERY_LIGHT_PINK = '#F7F7F7'
