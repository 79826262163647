import axios from 'axios'
import { SILO_API } from './endpoints'
import { firebaseUser } from './firebase/firebaseAuth'

export const updateSiloId = async (
  siloUid: string,
  siloId: string,
  sortOrder: number,
  fleetId?: string,
  customerId?: string,
  siloTypeId?: string,
  positionFleetUnitId?: number,
  thresholdAmber?: number,
  thresholdRed?: number,
) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.post(
      `${SILO_API}/${siloUid}`,
      {
        siloId,
        sortOrder,
        fleetId,
        customerId,
        siloTypeId,
        positionFleetUnitId,
        thresholdAmber,
        thresholdRed,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export type SingleSiloUpdateType = {
  siloId?: string
  siloTypeId?: string
  positionFleetUnitId?: number
  thresholdAmber?: number
  thresholdRed?: number
  fleetId?: string
  customerId?: string
}

export const udpateSingleSilo = async (siloUid: string, params: SingleSiloUpdateType) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.post(`${SILO_API}/${siloUid}`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const deleteSingleSilo = async (siloUid: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.delete(`${SILO_API}/${siloUid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const deleteSilos = async (siloIds: string[]) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.delete(SILO_API, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        siloIds,
      },
    })
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const createSingleSilo = async (params: SingleSiloUpdateType) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.post(SILO_API, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchSilos = async (take: number, skip: number) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.get(SILO_API, {
      params: {
        take,
        skip,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchSingleSilo = async (siloUid: string) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.get(`${SILO_API}/${siloUid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchModems = async () => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.get(`${SILO_API}/modems`, {
      params: {
        unassigned: true,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return results
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchSingleModem = async (unitId: number) => {
  const currentUser = firebaseUser()

  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.get(`${SILO_API}/modems/${unitId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return results
  }

  throw new Error('User not found. Please sign in again.')
}
