import { useEffect, useState } from 'react'
import { MessageProps } from '../../components/Toast'
import { fetchSilos } from '../../services/silos'
import { Silo } from '../types'

const useSilos = () => {
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const [loading, setLoading] = useState(true)
  const [silos, setSilos] = useState<Silo[]>([])
  const [totalSilos, setTotalSilos] = useState()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState()
  const [itemsPerPage] = useState(15)
  /* Default: Sort Data */
  const [resetData, setResetData] = useState(false)

  useEffect(() => {
    // Fetch fleet + customer details
    const getFleetAndCustomer = async (silos: Silo[]) => {
      const results = await Promise.all(
        silos.map(async (silo: Silo) => {
          if (silo.sandData) {
            // Add unit to weight
            const sandData = silo.sandData
            const currentWeight = silo.sandData.currentWeight

            return {
              ...silo,
              sandData: { ...sandData, currentWeight: currentWeight ? `${currentWeight}Lbs` : null },
            }
          }

          return silo
        }),
      )
      return results
    }

    const getSilos = async () => {
      try {
        setLoading(true)
        const response = await fetchSilos(itemsPerPage, page === 1 ? 0 : (page - 1) * itemsPerPage)
        const { silos, total } = response.data.data

        setTotalSilos(total)
        setTotalPages(Math.ceil(total / itemsPerPage))

        let newSilos = silos
        if (silos.length > 0) {
          newSilos = await getFleetAndCustomer(silos)
        }
        setSilos(newSilos)
        setResetData(true)
      } catch (error) {
        setToast({ value: error.message, type: 'error' })
      }
      setLoading(false)
      setResetData(false)
    }

    getSilos()
  }, [page, itemsPerPage])

  return {
    loading,
    silos,
    setSilos,
    toast,
    setToast,
    setLoading,
    page,
    setPage,
    totalPages,
    itemsPerPage,
    totalSilos,
    resetData,
  }
}

export default useSilos
