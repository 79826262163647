import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown } from 'react-feather'
import { interpolate, useSpring, useTrail } from 'react-spring'
import { useClickAway } from 'react-use'
import InputWrapper from '../InputWrapper'
import { Absolute, DropDownContainer, InputSearch, Item, Option, Relative, SelectContainer, Selected } from './styles'

interface DropDownProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick: React.Dispatch<React.SetStateAction<any>>
  label?: string
  error?: string
  options: string[]
  optionIds?: string[]
  value?: string
  search?: boolean
  labelLeft?: boolean
  disabled?: boolean
  required?: boolean
  info?: string
  placeholder?: string
  autoComplete?: string
}

/**
 * DropDown
 * @param onClick - Set state action with no parameters (useState)
 * @param label - Label text, also acts as the id
 * @param error - Display an error message on the bottom of the component
 * @param options - Array of strings to display in the list
 * @param optionIds - Array of strings from the data
 * @param placeholder - Placeholder for the input
 * @param value - Selected value
 * @param labelLeft - Place the label to the left side instead top
 * @param search - Allow type to filter
 * @param disabled - Block the current input
 * @param required - Will set this field as required
 * @param info - Info text for extra description
 * @param autoComplete - Turn on or off autoComplete on input (by default it's off)
 */

const DropDown = ({
  label,
  error,
  value,
  options,
  optionIds,
  onClick,
  labelLeft,
  disabled,
  required,
  info,
  search,
  placeholder,
  autoComplete = 'off',
  ...otherProps
}: DropDownProps) => {
  const optionsRef = useRef(null)
  const [toggle, setToggle] = useState(false)
  const [list, setList] = useState(options)

  const hideListAnim = useSpring({ display: toggle ? 'block' : 'none', delay: toggle ? 0 : 500 })
  const height = useSpring({ height: toggle ? list.length * 34 : 0, overflowY: list.length <= 4 ? 'hidden' : 'scroll' })

  const handleToggle = () => {
    if (document.activeElement && document.activeElement.id === 'patata' && list.length > 0) {
      setToggle(true)
      return
    }
    if (!disabled && list.length > 0) {
      setToggle(!toggle)
    }
  }
  const handleSelect = (item: string) => {
    if (optionIds && optionIds.length > 0) {
      const newItem = mapValue(item, true)

      onClick(newItem)
    } else {
      onClick(item)
    }

    handleToggle()
  }

  useEffect(() => {
    if (search && value) {
      const filterList = options.filter(x => value && x.toLowerCase().startsWith(value.toLowerCase()))
      setList(filterList)
    } else {
      setList(options)
    }
  }, [options, search, value])

  useClickAway(optionsRef, () => {
    setToggle(false)
  })

  const trail = useTrail(list.length, {
    config: { tension: 300 },
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 30,
    from: { opacity: 0, x: 0 },
  })

  const mapValue = (value: string | undefined, reverse = false) => {
    if (value) {
      if (optionIds && optionIds.length > 0) {
        if (reverse) return optionIds[list.findIndex(item => item === value)]
        return list[optionIds.findIndex(item => item === value)]
      }
      return value
    }
    return ''
  }

  return (
    <InputWrapper info={info} error={error} label={label} labelLeft={labelLeft} required={required}>
      <DropDownContainer {...otherProps}>
        <SelectContainer>
          {search && (
            <InputSearch
              autoComplete={autoComplete}
              id={`key-${value}`}
              toggle={toggle}
              search={search}
              onClick={handleToggle}
              placeholder={placeholder}
              value={mapValue(value)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onClick(e.target.value)}
              disabled={disabled}
            />
          )}
          <Selected simple error={error} onClick={handleToggle} disabled={disabled} toggle={toggle} search={search}>
            {!search && (!value ? placeholder : mapValue(value))}
            <ChevronDown size={20} />
          </Selected>
        </SelectContainer>

        <Relative ref={optionsRef} style={hideListAnim}>
          <Absolute style={height}>
            {trail.map(({ x, ...rest }, index) => (
              <Option
                key={index}
                simple
                onClick={() => handleSelect(list[index])}
                style={{
                  ...rest,
                  transform: interpolate([x], (x: any) => `translate3d(0,${x}px,0)`),
                }}
              >
                <Item>{list[index]}</Item>
              </Option>
            ))}
          </Absolute>
        </Relative>
      </DropDownContainer>
    </InputWrapper>
  )
}

export default DropDown
