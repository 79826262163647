import styled from 'styled-components'
import { LIGHT_GREY, PRIMARY, BLACK } from '../../config/styles/colours'
import { RESPONSIVE_BREAKPOINTS, Z_INDEX } from '../../config/styles/constants'
import { Link } from 'react-router-dom'

type TabSelectedProps = {
  selected?: boolean
}

export const NavBarContainer = styled.header`
  background-color: white;
  position: relative;
  z-index: ${Z_INDEX.medium};
  height: 70px;
  border-bottom: 1px solid ${LIGHT_GREY};
  padding: 0 12px;
  @media (min-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    padding: 0 5%;
  }
`
export const NavBarList = styled.ul`
  display: flex;
  height: 100%;
  > li:first-child {
    margin: 0;
  }
  justify-content: space-around;

  @media (min-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    justify-content: flex-start;
    > li:first-child {
      margin-right: 50px;
    }
    > li:last-child {
      margin-left: auto;
    }
  }
`
export const Li = styled.li`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
  border-bottom: ${({ selected }: TabSelectedProps) => (selected ? `2px solid ${PRIMARY}` : '2px solid transparent')};
  transition: all 0.3s ease-in-out;
  &:hover:not(:first-child) {
    border-bottom: 2px solid ${PRIMARY};
  }
  @media (min-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    margin-left: 25px;
  }
`
export const Logo = styled.img`
  height: 70%;
`

export const NavLink = styled(Link)`
  height: 100%;
  padding: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  background-color: transparent;
  border: 0;
  color: ${({ selected }: TabSelectedProps) => (selected ? `${PRIMARY}` : `${BLACK}`)};
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: ${PRIMARY};
  }
`
