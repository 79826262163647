import styled from 'styled-components/macro'
import { LIGHT_GREY, PALE_GREY, BLACK, COOL_GREY, PRIMARY, DANGER, REMOVE_ALL } from '../../config/styles/colours'
import { BORDER_RADIUS } from '../../config/styles/constants'
import Button from '../Button'
import DropDown from '../DropDown'

type ButtonProps = {
  defaultcursor?: boolean | string
}

export const Table = styled.table`
  width: 100%;
  border: 1px solid ${LIGHT_GREY};
  border-radius: ${BORDER_RADIUS}px;
  border-spacing: 0;
`
export const Thead = styled.thead`
  border-bottom: 1px solid ${LIGHT_GREY};
  padding: 20px 0;
  background-color: ${PALE_GREY};

  > tr {
    > th {
      border-bottom: 1px solid ${LIGHT_GREY};
      > button {
        width: 17px;
        height: 17px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        color: ${BLACK};

        align-items: center;
      }
      > svg {
        vertical-align: middle;
      }

      &:not(:first-child) {
        > button {
          display: flex;
          width: 100%;
          height: 100%;
          text-align: left;
        }
      }
    }
  }
`

export const Tbody = styled.tbody`
  > tr {
    > th {
      border-bottom: 1px solid ${LIGHT_GREY};
      text-align: center;
    }
    > td {
      border-bottom: 1px solid ${LIGHT_GREY};
      &:last-child {
        text-align: right;
        min-width: 82px;
      }
    }
    &:last-child {
      > th,
      > td {
        border-bottom: 0;
      }
    }
  }
`
export const Th = styled.th`
  padding: 10px;
  color: ${BLACK};
`
export const Td = styled.td`
  color: ${COOL_GREY};
  padding: 10px;
`
export const ButtonSettings = styled(Button)`
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${PRIMARY};
  }
`
export const ButtonDelete = styled(Button)`
  cursor: ${({ defaultcursor }: ButtonProps) => (defaultcursor === 'true' ? 'default' : 'pointer')};
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${DANGER};
  }
`
export const DTContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const TitleAndCount = styled.div`
  display: flex;
  align-items: center;
  > h2 {
    font-size: 16px;
    margin-right: 5px;
    margin-bottom: 0;
  }
  > p {
    color: ${LIGHT_GREY};
    font-size: 15px;
    margin-bottom: 0;
  }
`

export const DTHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`
export const AddDataButton = styled(Button)`
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 0;
  svg {
    margin-right: 10px;
  }
`

export const RemoveDataButton = styled(Button)`
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 0;
  background: ${REMOVE_ALL};
`

export const DTFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`

export const RPPDropDown = styled(DropDown)`
  div {
    background-color: transparent;
    border: 0;
  }
  button {
    font-size: 10px;
    background-color: transparent;
    border: 1px solid transparent;
    min-width: 60px;
    width: 60px;
    padding-right: 0;
    padding-left: 0;
  }
  svg {
    width: 15px;
    height: 15px;
  }
`

export const FakeSelector = styled.div`
  display: flex;
  justify-content: center;
  > div {
    width: 17px;
    height: 17px;
    background-color: ${LIGHT_GREY};
  }
`
export const FakeContent = styled.div`
  width: 100%;
  height: 17px;
  background-color: ${LIGHT_GREY};
`

export const FakeSettings = styled.div`
  display: flex;
  justify-content: flex-end;
  > div {
    width: 17px;
    height: 17px;
    background-color: ${LIGHT_GREY};
    &:first-child {
      margin-right: 12px;
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;

  > button {
    margin: 5px;
  }
`
