import AsyncSelect from 'react-select/async'
import styled from 'styled-components/macro'
import Button from '../../../../components/Button'
import { GlobalContainer } from '../../../../components/GlobalContainer'
import { InputGroup } from '../../../../components/InputGroup'
import InputWrapper from '../../../../components/InputWrapper'
import SimpleLink from '../../../../components/SimpleLink'
import { DANGER, PRIMARY } from '../../../../config/styles/colours'

export const BackTo = styled(SimpleLink)`
  align-self: flex-start;
  font-weight: bold;
`
export const SortSectionButton = styled(Button)`
  height: 20px;
  color: ${PRIMARY};
  align-self: center;
`

export const RemoveSectionButton = styled(Button)`
  height: 16px;
  color: ${DANGER};
  align-self: center;
`
export const AddAnotherButton = styled(Button)`
  align-self: flex-start;
  color: ${PRIMARY};
  padding: 0;
  margin-top: 12px;
`

export const AddEditFooter = styled.div`
  ${GlobalContainer}
  margin-top: 12px;
`
export const ErrorMessage = styled.p`
  color: ${DANGER};
`
export const CustomAsyncSelect = styled(AsyncSelect)`
  width: 100%;
`

export const CustomerInputWrapper = styled(InputWrapper)`
  ${InputGroup} > & {
    /* Put the margin as a padding to use directly max-width */
    margin-left: 0;
    max-width: 50%;
    padding-left: 5px;
  }
`
