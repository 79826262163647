import { useContext } from 'react'
import userContext from './userContext'
/**
 * Custom hook whick can be use in any component to get the user's properties
 * i.e: `const user = useSession()`
 */
const useSession = () => {
  const { user } = useContext(userContext)
  return user
}

export default useSession
