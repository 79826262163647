import update from 'immutability-helper'
import React, { useState } from 'react'
import { arrayMove, SortableElement } from 'react-sortable-hoc'
import { animated } from 'react-spring'
import Button from '../../../../components/Button'
import CheckBox from '../../../../components/CheckBox'
import Container from '../../../../components/Container'
import DropDown from '../../../../components/DropDown'
import { InputGroup } from '../../../../components/InputGroup'
import InputSection from '../../../../components/InputSection'
import InputTag from '../../../../components/InputTag'
import InputText from '../../../../components/InputText'
import Spinner from '../../../../components/Spinner'
import Toast from '../../../../components/Toast'
import useCreateCustomer from '../../../../config/hooks/useCreateCustomer'
import history from '../../../../config/router'
import { createCustomer } from '../../../../services/customers'
import { createFleet, updateFleet } from '../../../../services/fleets'
import { updateSiloId } from '../../../../services/silos'
import { checkIfUserExist, createUser } from '../../../../services/user'
import { DragHandle, SortContainer } from '../../../Fleets/screens/FleetAddEdit/components/Modems'
import StepBar from './components/StepBar'
import {
  AddAnotherButton,
  AddEditFooter,
  BackTo,
  CheckBoxContainer,
  DivBlock,
  ErrorMessage,
  LoadingContainer,
  RemoveSectionButton,
} from './styles'

const AddCustomer = () => {
  const {
    fleetList,
    customerFleetList,
    modemList,
    customerName,
    setCustomerName,
    customerUid,
    step,
    setStep,
    loadingData,
    toast,
    setToast,
    props,
  } = useCreateCustomer()

  const [loading, setLoading] = useState(false)

  const [customerNameError, setCustomerNameError] = useState('')

  const [customerId, setCustomerId] = useState('')
  const [customerIdError, setCustomerIdError] = useState('')

  const [fleets, setFleets] = useState<{ fleetId?: string; location?: string; fleetUid?: string }[]>([
    { fleetId: undefined, location: undefined, fleetUid: undefined },
  ])

  const [fleetsError, setFleetsError] = useState('')

  const [silos, setSilos] = useState([
    { modemIdentifier: '', siloId: '', assignFleetId: '', fleetUid: '', siloUid: '', siloSortOrder: 0 },
  ])
  const [silosError, setSilosError] = useState('')

  const [users, setUsers] = useState([{ email: '', name: '', assignedFleets: [], checked: false }])
  const [userError, setUserError] = useState('')

  const [inputValueAssignedFleets, setInputValueAssignedFleets] = useState('')

  /**
   * Start - Handle Sort
   */

  const SortableItem = SortableElement(({ silo, siloIndex }: any) => {
    return (
      <li>
        <InputGroup key={siloIndex}>
          <DragHandle />
          <DropDown
            required
            disabled={loading}
            label="Silo ID (Unit)"
            placeholder="ID (Unit)"
            value={silo.modemIdentifier}
            options={modemList.map(x => x.alias)}
            onClick={value => {
              const val = modemList.find(x => x.alias === value) || value
              handleMultiInputChange(val, siloIndex, silos, 'modemIdentifier', setSilos)
            }}
            search
          />
          <InputText
            value={silo.siloId}
            label="Assign Silo ID"
            placeholder="Silo ID"
            onChange={e => handleMultiInputChange(e.target.value, siloIndex, silos, 'siloId', setSilos)}
            disabled={loading}
          />

          <DropDown
            required
            disabled={loading}
            label="Assign Fleet"
            placeholder="Fleet ID"
            value={silo.assignFleetId}
            options={customerFleetList.map(x => x.fleetId)}
            onClick={value => {
              const val = customerFleetList.find(x => x.fleetId === value) || value
              handleMultiInputChange(val, siloIndex, silos, 'assignFleetId', setSilos)
            }}
            search
          />

          <RemoveSectionButton icon="X" simple onClick={() => removeMultiInputFromArray(siloIndex, silos, setSilos)} />
        </InputGroup>
      </li>
    )
  })

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const movedSilos = arrayMove(silos, oldIndex, newIndex)
    if (movedSilos) {
      const newArray: any[] = movedSilos.map((s: any, index) => {
        return silos.find(a => {
          if (a.modemIdentifier === s.modemIdentifier) {
            const newOrderedSilo = a
            a.siloSortOrder = index

            return newOrderedSilo
          }
          return null
        })
      })
      setSilos(newArray)
    }
  }

  /**
   * End - Handle Sort
   */

  const resetErrors = () => {
    if (customerNameError) {
      setCustomerNameError('')
    }
    if (silosError) {
      setSilosError('')
    }
    if (userError) {
      setUserError('')
    }
  }

  const handleContinue = async () => {
    resetErrors()

    let errors = 0

    try {
      setLoading(true)
      if (step === 1) {
        // * Validation
        if (!customerName) {
          setCustomerNameError('This field is required, please add a customer name.')
          errors += 1
        }

        if (!customerId) {
          setCustomerIdError('This field is required, please add a customer ID.')
          errors += 1
        }

        if (errors) {
          setLoading(false)
          return
        }

        const response = await createCustomer(customerName, customerId)
        setToast({ value: response.data.message })
      }

      if (step === 2) {
        if (fleets.length === 0) {
          setFleetsError('To continue its necessary to assign or create a Fleet.')
          errors += 1
        } else {
          // * If the select Fleet
          // * - Exist on the DB, don't need to check location
          // * - don't exist on the DB, check that both fields have content
          const validateFleets = fleets.some(
            fleet => fleet.fleetId && !fleetList.some(x => x.fleetId === fleet.fleetId) && !fleet.location,
          )

          if (validateFleets) {
            setFleetsError('Missing values on Fleets, please add all required data.')
            errors += 1
          }
        }

        if (errors) {
          setLoading(false)
          return
        }

        const fleetPromises: {
          type: string
          customerUid?: string
          fleetUid?: string
          fleetId?: string
          location?: string
        }[] = []

        fleets.map(fleet => {
          const exist = fleetList.some(x => x.fleetId === fleet.fleetId)
          if (exist) {
            return fleetPromises.push({
              customerUid,
              fleetUid: fleet.fleetUid,
              fleetId: undefined,
              location: undefined,
              type: 'update',
            })
          }
          return fleetPromises.push({
            customerUid,
            fleetId: fleet.fleetId,
            location: fleet.location,
            type: 'create',
          })
        })

        await Promise.all(
          fleetPromises.map(params => {
            const { customerUid, fleetUid, fleetId, location, type } = params
            if (type === 'update') {
              // @ts-ignore
              return updateFleet(fleetUid, fleetId, location, customerUid)
            }
            if (type === 'create') {
              // @ts-ignore
              return createFleet(fleetId, location, customerUid)
            }
            return null
          }),
        )

        setToast({ value: 'Fleets assigned successfully.' })
      }

      if (step === 3) {
        if (silos.length === 0) {
          setSilosError('To continue its necessary assign a silo.')
          errors += 1
        } else {
          const checkForEmpty = silos.some(
            ({ modemIdentifier, siloId, assignFleetId }) => !modemIdentifier || !siloId || !assignFleetId,
          )
          if (checkForEmpty) {
            setSilosError('Some of the values are empty. Please fill the missing ones.')
            errors += 1
          }
        }

        if (errors) {
          setLoading(false)
          return
        }

        const siloPromises: {
          siloUid: string
          siloId: string
          fleetUid: string
          customerUid: string
          siloSortOrder: number
        }[] = []

        silos.map(silo =>
          siloPromises.push({
            customerUid,
            siloUid: silo.siloUid,
            siloId: silo.siloId,
            fleetUid: silo.fleetUid,
            siloSortOrder: silo.siloSortOrder,
          }),
        )

        await Promise.all(
          siloPromises.map(params => {
            const { siloUid, siloId, fleetUid, customerUid, siloSortOrder } = params
            return updateSiloId(siloUid, siloId, siloSortOrder, fleetUid, customerUid)
          }),
        )
        setToast({ value: 'Silos assigned successfully.' })
      }

      if (step === 4) {
        if (users.length === 0) {
          setUserError('To Finish its necessary assign a user.')
          errors += 1
        } else {
          const checkForEmpty = users.some(
            ({ email, assignedFleets, checked }) => !email || (!checked && assignedFleets.length === 0),
          )
          if (checkForEmpty) {
            setUserError('Some of the values are empty. Please fill the missing ones.')
            errors += 1
          }

          const ifExist = users.some(user => {
            const convert = customerFleetList.map(x => x.fleetId)
            return user.assignedFleets.every(e => convert.includes(e))
          })

          if (!ifExist) {
            setUserError('Some of the fleets dont exist, please enter real fleets.')
            errors += 1
          }
        }

        const userPromises: {
          role: string
          name: string
          email: string
          managedCustomerIds?: string[]
          relatingCustomerId?: string
          accessibleFleetIds?: string[]
        }[] = []

        const allUserExist = await Promise.all(
          users.map(user => {
            const userFleetUids: string[] = []

            user.assignedFleets.map(fleetId => {
              const findFleet = customerFleetList.find(fleet => fleet.fleetId === fleetId)
              if (findFleet) return userFleetUids.push(findFleet.fleetUid)

              return null
            })

            userPromises.push({
              role: 'EXTERNAL_USER',
              name: user.name,
              email: user.email,
              managedCustomerIds: undefined,
              relatingCustomerId: customerUid,
              accessibleFleetIds: userFleetUids.length > 0 ? userFleetUids : undefined,
            })
            return checkIfUserExist(user.email)
          }),
        )

        if (allUserExist.filter(x => x === null).length === 0) {
          errors += 1
          setUserError('This user already exists on the database.')
        }

        if (errors) {
          setLoading(false)
          return
        }

        await Promise.all(
          userPromises.map(params => {
            const { role, name, email, managedCustomerIds, relatingCustomerId, accessibleFleetIds } = params
            return createUser(role, name, email, managedCustomerIds, relatingCustomerId, accessibleFleetIds)
          }),
        )

        history.push('/customers', { routerNotification: 'Customer successfully created.' })
      }

      setLoading(false)

      if (step < 4) {
        setStep(step + 1)
        return
      }
    } catch (error) {
      setLoading(false)
      setToast({ value: error.response.data.error.reason, type: 'error' })
    }
  }

  const handleMultiInputChange = (
    value: any,
    i: number,
    data: any,
    inputName: string,
    setFn: React.Dispatch<React.SetStateAction<any>>,
  ) => {
    if (inputName === 'fleetId') {
      const changedData = update(data, {
        [i]: {
          [inputName]: { $set: value.fleetId || value },
          fleetUid: { $set: value.fleetUid || undefined },
          location: { $set: undefined },
        },
      })
      setFn(changedData)
      return
    }

    if (inputName === 'assignFleetId') {
      const changedData = update(data, {
        [i]: {
          [inputName]: { $set: value.fleetId || value },
          fleetUid: { $set: value.fleetUid || '' },
        },
      })
      setFn(changedData)
      return
    }

    if (inputName === 'modemIdentifier') {
      const changedData = update(data, {
        [i]: {
          [inputName]: { $set: value.mdmid || value },
          siloId: { $set: value.siloId || '' },
          siloUid: { $set: value.siloUid || '' },
        },
      })
      setFn(changedData)
      return
    }
    const changedData = update(data, {
      [i]: { [inputName]: { $set: value } },
    })
    setFn(changedData)
    return
  }

  const removeMultiInputFromArray = (i: number, data: any, setFn: React.Dispatch<React.SetStateAction<any>>) => {
    const updatedData = update(data, { $splice: [[i, 1]] })
    setFn(updatedData)
  }

  const newFleetInput = () => setFleets([...fleets, { fleetId: undefined, location: undefined, fleetUid: undefined }])
  const newSiloInput = () =>
    setSilos([
      ...silos,
      { modemIdentifier: '', siloId: '', assignFleetId: '', fleetUid: '', siloUid: '', siloSortOrder: 0 },
    ])
  const newUserInput = () => setUsers([...users, { email: '', name: '', assignedFleets: [], checked: false }])

  const handleCheckbox = (value: boolean, index: number) => {
    const changedData = update(users, {
      [index]: { assignedFleets: { $set: [] }, checked: { $set: !value } },
    })
    setUsers(changedData)
  }

  const handleSkip = () => {
    // TODO: do something for skip
    history.push('/customers', { routerNotification: 'Action Skipped' })
  }

  const autocompleteFleets = (value: string) => {
    const customerFleetListId: string[] = []
    customerFleetList.filter(
      x => x.fleetId && x.fleetId.toLowerCase().startsWith(value.toLowerCase()) && customerFleetListId.push(x.fleetId),
    )
    return customerFleetListId
  }

  return (
    <>
      <Toast message={toast} action={setToast} />
      <StepBar step={step} />
      <Container title="Add New Customer" secondary>
        <BackTo to="/customers" icon="ArrowLeft">
          Back to Customers
        </BackTo>
        {loadingData ? (
          <LoadingContainer>
            <Spinner message={`Loading step ${step}`} />
          </LoadingContainer>
        ) : (
          <animated.div style={props}>
            {step === 1 && (
              <InputSection title="Customer Details">
                <InputGroup>
                  <InputText
                    required
                    disabled={loading}
                    value={customerName}
                    onChange={e => setCustomerName(e.target.value)}
                    label="Customer Name"
                    placeholder="Enter Customer Name"
                    error={customerNameError}
                  />

                  <InputText
                    required
                    value={customerId}
                    label="Customer ID"
                    onChange={e => setCustomerId(e.target.value)}
                    placeholder="Enter Customer ID"
                    error={customerIdError}
                    disabled={loading}
                  />
                </InputGroup>
              </InputSection>
            )}
            {step === 2 && (
              <InputSection title="Create Fleet" subtitle="Assign an existent or create a new Fleet">
                {fleets.map((fleet, index) => (
                  <InputGroup key={index}>
                    <DropDown
                      required
                      disabled={loading}
                      label="Fleet ID"
                      placeholder="Add a Fleet ID"
                      value={fleet.fleetId}
                      options={fleetList.map(x => x.fleetId)}
                      onClick={value => {
                        const val = fleetList.find(x => x.fleetId === value) || value
                        handleMultiInputChange(val, index, fleets, 'fleetId', setFleets)
                      }}
                      search
                    />

                    {!fleetList.some(x => x.fleetId === fleet.fleetId) ? (
                      <>
                        <InputText
                          required
                          value={fleet.location}
                          label="Location"
                          placeholder="Type location"
                          onChange={e => handleMultiInputChange(e.target.value, index, fleets, 'location', setFleets)}
                          disabled={loading}
                        />
                        <RemoveSectionButton
                          icon="X"
                          simple
                          onClick={() => removeMultiInputFromArray(index, fleets, setFleets)}
                        />
                      </>
                    ) : (
                      <>
                        <RemoveSectionButton
                          icon="X"
                          simple
                          onClick={() => removeMultiInputFromArray(index, fleets, setFleets)}
                        />
                        <div />
                      </>
                    )}
                  </InputGroup>
                ))}
                {fleetsError && <ErrorMessage>{fleetsError}</ErrorMessage>}
                <AddAnotherButton icon="PlusCircle" simple onClick={newFleetInput}>
                  Add Another
                </AddAnotherButton>
              </InputSection>
            )}
            {step === 3 && (
              <InputSection title="Assign Silos" subtitle="Please assign any existing silos that a customer has.">
                <SortContainer onSortEnd={onSortEnd} useDragHandle>
                  {silos.map((silo, index) => {
                    return <SortableItem key={`item-${silo.siloUid}`} index={index} siloIndex={index} silo={silo} />
                  })}
                </SortContainer>

                {silosError && <ErrorMessage>{silosError}</ErrorMessage>}
                <AddAnotherButton icon="PlusCircle" simple onClick={newSiloInput}>
                  Add Another
                </AddAnotherButton>
              </InputSection>
            )}
            {step === 4 && (
              <InputSection
                title="Add Users"
                subtitle="Please add at least one user. Users invited here will receive an email with instructions as to how to access the app and their account."
              >
                {users.map((user, index) => (
                  <DivBlock key={index}>
                    <InputGroup>
                      <InputText
                        value={user.email}
                        label="Email Address"
                        placeholder="User Email"
                        onChange={e => handleMultiInputChange(e.target.value, index, users, 'email', setUsers)}
                        required
                        disabled={loading}
                      />

                      <InputText
                        value={user.name}
                        label="User name"
                        placeholder="Add a user name"
                        onChange={e => handleMultiInputChange(e.target.value, index, users, 'name', setUsers)}
                        disabled={loading}
                      />

                      <InputTag
                        label="Assigned Fleets"
                        disabled={loading || user.checked}
                        placeholder={
                          user.assignedFleets.length === 0
                            ? user.checked
                              ? 'All Fleets Assigned'
                              : 'Type Fleet ids'
                            : ''
                        }
                        items={user.assignedFleets}
                        setItems={value => handleMultiInputChange(value, index, users, 'assignedFleets', setUsers)}
                        info="Please press enter key to submit values"
                        searchFN={autocompleteFleets}
                        value={inputValueAssignedFleets}
                        setValue={setInputValueAssignedFleets}
                        required
                        full
                      />

                      <RemoveSectionButton
                        icon="X"
                        simple
                        onClick={() => removeMultiInputFromArray(index, users, setUsers)}
                      />
                    </InputGroup>
                    <CheckBoxContainer>
                      <CheckBox toggle={user.checked} onClick={() => handleCheckbox(user.checked, index)} />
                      <p>Assign All Fleets</p>
                    </CheckBoxContainer>
                  </DivBlock>
                ))}
                {userError && <ErrorMessage>{userError}</ErrorMessage>}
                <AddAnotherButton icon="PlusCircle" simple onClick={newUserInput}>
                  Add Another
                </AddAnotherButton>
              </InputSection>
            )}
          </animated.div>
        )}
      </Container>
      <AddEditFooter>
        {step === 4 ? (
          <Button onClick={handleContinue} loading={loading}>
            Finished
          </Button>
        ) : (
          <>
            <Button onClick={handleContinue} loading={loading}>
              Continue
            </Button>
            <Button simple onClick={handleSkip} loading={loading}>
              finish
            </Button>
          </>
        )}
      </AddEditFooter>
    </>
  )
}

export default AddCustomer
