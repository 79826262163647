import React from 'react'
import Container from '../../components/Container'
import DataTable from '../../components/DataTable'
import Toast from '../../components/Toast'
import useCustomers from '../../config/hooks/useCustomers'
import { Customer } from '../../config/types'
import { deleteCustomers } from '../../services/customers'

const Customers = () => {
  const {
    customers,
    setCustomers,
    loading,
    toast,
    setToast,
    setLoading,
    page,
    setPage,
    totalPages,
    totalCustomers,
    resetData,
  } = useCustomers()

  const handleDelete = async (ids: string[]) => {
    try {
      setLoading(true)
      await deleteCustomers(ids)

      setCustomers(customers.filter(c => !ids.includes(c.id)))

      if (ids.length > 1) {
        setToast({ value: 'All customers selected have been deleted successfully' })
      } else {
        const customerDeleted = customers.find(c => c.id === ids[0]) as Customer
        setToast({ value: `Customer ${customerDeleted.name} has been deleted successfully` })
      }
    } catch (error) {
      setToast({ value: error.response.data.error.reason, type: 'error' })
    }

    setLoading(false)
  }

  return (
    <>
      <Toast message={toast} action={setToast} />
      <Container title="Manage Customers">
        <DataTable
          headerTitles={['Customer Name', 'Users', 'Silos', 'Fleets']}
          dataToDisplay={['name', 'customerId', 'silos', 'fleets']}
          data={customers}
          title="Customers"
          loading={loading}
          addNewButton="Add Customer"
          addNewRoute="/new-customer"
          editRoute="/customers"
          removeAllButton="Remove Selected"
          deleteItem={handleDelete}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          totalItems={totalCustomers}
          resetSortData={resetData}
        />
      </Container>
    </>
  )
}

export default Customers
