import { useEffect, useState } from 'react'
import { useSpring } from 'react-spring'
import { MessageProps } from '../../components/Toast'
import { fetchCustomers, fetchCustomersFleets } from '../../services/customers'
import { fetchFleets } from '../../services/fleets'
import { fetchAllAssignedModems } from '../../services/modems'

type FleetList = {
  fleetUid: string
  fleetId: string
}

type ModemList = {
  alias: string
  mdmid: string
  siloUid: string
  siloId: string
}

const useCreateCustomer = () => {
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const [fleetList, setFleetList] = useState<FleetList[]>([])
  const [customerFleetList, setCustomerFleetList] = useState<FleetList[]>([])
  const [modemList, setModemList] = useState<ModemList[]>([])
  const [customerName, setCustomerName] = useState('')
  const [customerUid, setCustomerUid] = useState('')
  const [loadingData, setLoadingData] = useState(true)
  const [step, setStep] = useState(1)
  const [props, set] = useSpring(() => ({ opacity: 0 }))

  useEffect(() => {
    const handleFleetList = async () => {
      setLoadingData(true)
      set({ opacity: 0 })
      const fleetsResponse = await fetchFleets(undefined, undefined, true)
      const { fleets } = fleetsResponse.data.data
      if (fleets && fleets.length > 0) {
        const formatFleetList = fleets.map(fleet => ({
          fleetUid: fleet.id,
          fleetId: fleet.fleetId,
        }))
        setFleetList(formatFleetList)
      }
      setLoadingData(false)
      set({ opacity: 1 })
    }

    const handleSetCustomerId = async () => {
      const customersResponse = await fetchCustomers({ name: customerName })
      setCustomerUid(customersResponse.data.data.customers[0].id)
    }
    // TODO: Fleet uid its undefined
    const getAllModems = async () => {
      setLoadingData(true)
      set({ opacity: 0 })
      const modemsResponse = await fetchAllAssignedModems()
      const { data } = modemsResponse.data

      const collectModems = data.map(modem => {
        return {
          alias: modem.alias,
          mdmid: modem.mdmid,
          siloUid: modem.silo ? modem.silo.id : '',
          siloId: modem.silo ? modem.silo.siloId : '',
        }
      })

      const fleets = await fetchCustomersFleets(customerUid)
      if (fleets && fleets.length > 0) {
        const formatFleetList = fleets.map(fleet => ({
          fleetUid: fleet.id,
          fleetId: fleet.fleetId,
        }))
        setCustomerFleetList(formatFleetList)
      }

      setModemList(collectModems)
      setLoadingData(false)
      set({ opacity: 1 })
    }

    handleFleetList()
    if (step === 2) {
      handleSetCustomerId()
    } else if (step === 3) {
      getAllModems()
    } else {
      setLoadingData(false)
      set({ opacity: 1 })
    }
  }, [set, step, customerName, customerUid])

  return {
    fleetList,
    customerFleetList,
    modemList,
    customerName,
    setCustomerName,
    customerUid,
    step,
    setStep,
    loadingData,
    toast,
    setToast,
    props,
  }
}

export default useCreateCustomer
