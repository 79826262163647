import React from 'react'
import { StepBarContainer, Step, Bar } from './styles'

interface StepBarProps {
  step: number
}

const StepBar = ({ step }: StepBarProps) => (
  <>
    <StepBarContainer>
      <Step active={step === 1} done={step > 1} />
      <Bar done={step > 1} />
      <Step active={step === 2} done={step > 2} />
      <Bar done={step > 2} />
      <Step active={step === 3} done={step > 3} />
      <Bar done={step > 3} />
      <Step active={step === 4} done={step > 4} />
    </StepBarContainer>
  </>
)

export default StepBar
