import styled from 'styled-components'
import { Z_INDEX, RESPONSIVE_BREAKPOINTS } from '../../config/styles/constants'
import { animated } from 'react-spring'
import { LIGHT_GREY, DANGER } from '../../config/styles/colours'
import Button from '../Button'

export const Overlay = styled(animated.div)`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${Z_INDEX.modal};
`
export const ModalContent = styled(animated.div)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 30%;
  min-width: 271px;
  background-color: white;
  position: relative;
  @media (max-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    width: 80%;
  }
`
export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${LIGHT_GREY};
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
`
export const ModalTitle = styled.h2`
  margin: 0;
  font-size: 20px;
`
export const Close = styled(Button)`
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 12px;
  font-size: 12px;
  transition: 0.3s color ease-in-out;
  &:hover {
    color: ${DANGER};
  }
  > svg {
    margin-left: 5px;
  }
`
