import React from 'react'
import styled, { css } from 'styled-components'
import { animated } from 'react-spring'
import {
  PRIMARY,
  PRIMARY_HOVER,
  COOL_GREY,
  DANGER,
  DANGER_HOVER,
  VERY_LIGHT_PINK,
  BLACK,
} from '../../config/styles/colours'
import { BORDER_RADIUS } from '../../config/styles/constants'

type ButtonStyledProps = {
  simple?: boolean
  negative?: boolean
  secondary?: boolean
  full?: boolean
}

type WithIconProps = {
  iconRight?: boolean
}

const ButtonBasics = css<ButtonStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ full }) => (full ? '100%' : 'auto')};
  padding: 0 20px;
  height: 35px;
  color: white;
  transition: 0.3s all ease-in-out;
  margin-bottom: 12px;
  border-radius: ${BORDER_RADIUS}px;
`

const Classic = css`
${ButtonBasics}
  background-color: ${PRIMARY};
  &:hover {
    background-color: ${PRIMARY_HOVER};
    border: 1px solid ${PRIMARY};
  }
`

const Negative = css`
${ButtonBasics}
  background-color: ${DANGER};
  color: white;
  &:hover {
    background-color: ${DANGER_HOVER};
    border: 1px solid ${DANGER};
  }
`
const Secondary = css`
${ButtonBasics}
  background-color: ${VERY_LIGHT_PINK};
  color: ${BLACK};
  &:hover {
    color: white;
    background-color: ${COOL_GREY};
    border: 1px solid ${VERY_LIGHT_PINK};
  }
`

export const ButtonStyled = styled(({ negative, secondary, simple, ...props }) => {
  return <animated.button {...props} />
})<ButtonStyledProps>`
  cursor: pointer;
  background-color: transparent;
  color: ${COOL_GREY};
  outline: 0;
  border: 1px solid transparent;
  ${({ simple, negative, secondary }) => {
    if (!simple) {
      if (negative) {
        return Negative
      }
      if (secondary) {
        return Secondary
      }

      return Classic
    }
    return null
  }}
`

export const WithIcon = styled.div<WithIconProps>`
  display: flex;
  flex-direction: ${({ iconRight }) => (iconRight ? 'row-reverse' : 'row')};
  justify-content: center;
  align-content: center;
  > svg {
    ${({ iconRight }) => (iconRight ? { marginLeft: 5 } : { marginRight: 5 })};
  }
`
