import React from 'react'
import { LoadingContainer } from './styles'
import InputText from '../../../../../../components/InputText'
import GoogleMaps from '../../../../../../components/GoogleMaps'
import { InputGroup } from '../../../../../../components/InputGroup'
import Spinner from '../../../../../../components/Spinner'

interface SiloDetailsGroupProps {
  currentWeight?: string
  latitude?: number
  longitude?: number
  blender?: string
  unitAddress?: string
  sandType?: string
  loading?: boolean
}

const SiloDetailsGroup = ({
  currentWeight = '',
  longitude = 0,
  latitude = 0,
  blender = '',
  unitAddress = '',
  sandType = '',
  loading = false,
}: SiloDetailsGroupProps) => {
  return (
    <InputGroup style={{ alignItems: 'center' }}>
      {loading ? (
        <LoadingContainer>
          <Spinner message="Fetching Silo details..." />
        </LoadingContainer>
      ) : (
        <>
          <div>
            <InputText value={`${currentWeight} Lbs` || ''} label="Current Weight" disabled />
            <InputGroup>
              <InputText value={latitude || 0} label="Latitude" disabled />
              <InputText value={longitude || 0} label="Longitude" disabled />
            </InputGroup>
            <InputText value={unitAddress || ''} label="Display Address" disabled />
            <InputGroup>
              <InputText value={blender || ''} label="Blender #" disabled />
              <InputText value={sandType || ''} label="Sand Type" disabled />
            </InputGroup>
          </div>
          {latitude && longitude ? <GoogleMaps coords={{ lat: latitude, lng: longitude }} /> : <div />}
        </>
      )}
    </InputGroup>
  )
}

export default SiloDetailsGroup
