import React, { useState, useRef } from 'react'
import { animated } from 'react-spring'
import { match } from 'react-router'
import Container from '../../../../components/Container'
import InputSection from '../../../../components/InputSection'
import InputText from '../../../../components/InputText'
import { AddEditFooter, BackTo, SelectAssignedCustomers } from './styles'
import Button from '../../../../components/Button'
import Toast from '../../../../components/Toast'
import Spinner from '../../../../components/Spinner'
import DropDown from '../../../../components/DropDown'
import { validateEmail } from '../../../../config/utils/functions'
import { InputGroup } from '../../../../components/InputGroup'
import useSingleUser from '../../../../config/hooks/useSingleUser'
import { createUser, updateUser } from '../../../../services/user'
import InputWrapper from '../../../../components/InputWrapper'
import history from '../../../../config/router'

interface UsersEditProps {
  match: match<{ id: string }>
}

const UsersEdit = ({ match }: UsersEditProps) => {
  const { id } = match.params

  const {
    options, // list of all existing customers on the platform
    assignedCustomers,
    setAssignedCustomers,
    name,
    setName,
    role,
    setRole,
    email,
    setEmail,
    toast,
    setToast,
    loadingData,
    props,
  } = useSingleUser(id)
  const [previousRole, setPreviousRole] = useState('')

  const emailRef = useRef<HTMLLabelElement>(null)
  const nameRef = useRef<HTMLLabelElement>(null)

  const [emailError, setEmailError] = useState('')
  const [nameError, setNameError] = useState('')

  const [loading, setLoading] = useState(false)

  const resetErrors = () => {
    if (emailError) setEmailError('')
    if (nameError) setNameError('')
  }

  const manageErrors = () => {
    let error = 0

    if (!name) {
      setNameError('This field is required, please add a name.')
      error += 1
      if (nameRef && nameRef.current) {
        nameRef.current.focus()
      }
    }
    if (!email) {
      setEmailError('This field is required, please add an email address.')
      error += 1
      if (emailRef && emailRef.current) {
        emailRef.current.focus()
      }
    }
    if (email && !validateEmail(email)) {
      setEmailError('Wrong format, please enter a valid email address.')
      error += 1
      if (emailRef && emailRef.current) {
        emailRef.current.focus()
      }
    }

    return error
  }

  const handleUpdate = async (previousRole: string) => {
    resetErrors()

    const countErrors = manageErrors()
    if (countErrors > 0) {
      return
    }

    setLoading(true)

    try {
      const ids = assignedCustomers ? assignedCustomers.map(c => c.value) : []
      const response = await updateUser(id, previousRole, role, name, email, ids)

      setToast({ value: response.data.message })
    } catch (error) {
      console.log({ error })
      if (error.response && error.response.status && error.response.status === 403) {
        setToast({ value: "You can't do that as an admin. You need a super admin account.", type: 'error' })
      } else {
        setToast({ value: error.message, type: 'error' })
      }
    }

    setLoading(false)
    history.push('/smartsand-users') // if user role is changed, need to redirect since new entity is created with a new id
  }

  const handleCreate = async () => {
    resetErrors()

    const countErrors = manageErrors()
    if (countErrors > 0) {
      return
    }

    setLoading(true)

    try {
      const ids = assignedCustomers ? assignedCustomers.map(c => c.value) : []
      const response = await createUser(role, name, email, ids)

      setName('')
      setEmail('')
      setAssignedCustomers([])
      setToast({ value: response.data.message })
    } catch (error) {
      console.log({ error })
      if (error.response.status === 403) {
        setToast({ value: "You can't do that as an admin. You need a super admin account.", type: 'error' })
      } else {
        setToast({ value: error.response.data.error.reason, type: 'error' })
      }
    }

    setLoading(false)
  }

  if (loadingData) {
    return <Spinner message="Fetching Data..." />
  }

  if (previousRole === '') {
    setPreviousRole(role)
  }

  return (
    <>
      <Toast message={toast} action={setToast} />
      <animated.div style={props}>
        <Container title={id ? 'Update User' : 'Add New User'} secondary>
          <BackTo to="/smartsand-users" icon="ArrowLeft">
            Back to Smart Sand Users
          </BackTo>
          <InputSection title="Update User">
            <InputGroup>
              <DropDown
                options={['Admin', 'Super Admin', 'Operator']}
                optionIds={['ADMIN', 'SUPER_ADMIN', 'OPERATOR']}
                disabled={loading}
                label="User Role"
                value={role}
                onClick={setRole}
              />

              <InputText
                required
                disabled={loading}
                value={name}
                onChange={e => setName(e.target.value)}
                label="Name"
                placeholder="User Name"
                labelRef={nameRef}
                error={nameError}
              />
            </InputGroup>

            <InputGroup>
              <InputText
                required
                disabled={loading}
                value={email}
                onChange={e => setEmail(e.target.value)}
                label="Email Address"
                placeholder="User Email"
                labelRef={emailRef}
                error={emailError}
              />

              {role === 'OPERATOR' ? (
                <InputWrapper label="Assigned Customers" info="Leave empty to give access to all customers">
                  <SelectAssignedCustomers
                    defaultValue={assignedCustomers}
                    isMulti
                    isDisabled={loading}
                    name="Assigned Customers"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(value: Array<{ value: string; label: string }>) => setAssignedCustomers(value)}
                  />
                </InputWrapper>
              ) : (
                <div />
              )}
            </InputGroup>
          </InputSection>
        </Container>
        <AddEditFooter>
          <Button onClick={id ? () => handleUpdate(previousRole || role) : () => handleCreate()} loading={loading}>
            {id ? 'Update' : 'Add'} User
          </Button>
        </AddEditFooter>
      </animated.div>
    </>
  )
}

export default UsersEdit
