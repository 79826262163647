import { useEffect, useState } from 'react'
import { MessageProps } from '../../components/Toast'
import { fetchUsers } from '../../services/user'
import { Customer, User } from '../types'

const useUsers = () => {
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState<User[]>([])
  const [totalUsers, setTotalUsers] = useState()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  // const [itemsPerPage, setItemsPerPage] = useState(15)
  const itemsPerPage = 15
  /* Default: Sort Data */
  const [resetData, setResetData] = useState(false)

  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoading(true)
        const response = await fetchUsers(itemsPerPage, page === 1 ? 0 : (page - 1) * itemsPerPage, [
          'OPERATOR',
          'ADMIN',
          'SUPER_ADMIN',
        ])
        const { users, total } = response.data.data
        setTotalUsers(total)
        setTotalPages(Math.ceil(total / itemsPerPage))

        const filteredUsers = users.map((user: User) => {
          if (user.managedCustomers) {
            return {
              ...user,
              managedCustomersNames: user.managedCustomers.map((c: Customer) => c.name),
            }
          }
          return user
        })

        setUsers(filteredUsers)
        setResetData(true)
      } catch (error) {
        setToast({ value: error.message, type: 'error' })
      }
      setLoading(false)
      setResetData(false)
    }

    getUsers()
  }, [page, itemsPerPage])

  return {
    loading,
    users,
    setUsers,
    toast,
    setToast,
    setLoading,
    page,
    setPage,
    totalPages,
    itemsPerPage,
    totalUsers,
    resetData,
  }
}

export default useUsers
