import React, { RefObject } from 'react'
import InputWrapper from '../InputWrapper'
import { InputStyled, GroupContainer, Group } from './styles'

export interface InputTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
  info?: string
  labelRef?: RefObject<HTMLLabelElement>
  group?: string
  autoComplete?: string
}

/**
 * InputText
 * @param label - Label text, also acts as the id
 * @param info - Info text for extra description
 * @param error - Display an error message on the bottom of the component
 * @param labelRef - Create a reference for the label, use cases: focus on error
 * @param group - Add a square to the right with the entered text
 * @param autoComplete - Turn on or off autoComplete on input (by default it's off)
 */

const InputText = ({ label, labelRef, error, info, group, autoComplete = 'off', ...otherProps }: InputTextProps) => {
  return (
    <InputWrapper error={error} label={label} labelRef={labelRef} required={otherProps.required} info={info}>
      <GroupContainer>
        <InputStyled id={label} error={error} group={group} autoComplete={autoComplete} {...otherProps} />
        {group && (
          <Group error={error}>
            <p>{group}</p>
          </Group>
        )}
      </GroupContainer>
    </InputWrapper>
  )
}

export default InputText
