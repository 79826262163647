import React from 'react'
import { ContainerStyled, Title } from './styles'

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  children: React.ReactNode
  secondary?: boolean
  titleMargin?: number
}

/**
 * Container
 * @param title - Main h1 for the page title
 * @param children - Pass any component
 * @param titleMargin - Set the title margin, 50 by default. only available for non secondary styles
 */

const Container = ({ title, children, secondary, titleMargin = 50, ...otherProps }: ContainerProps) => (
  <ContainerStyled secondary={secondary} {...otherProps}>
    <Title secondary={secondary} titleMargin={titleMargin}>
      {title}
    </Title>
    {children}
  </ContainerStyled>
)

export default Container
