import React from 'react'
import { useSpring } from 'react-spring'
import { CheckBoxContainer, IconContainer } from './styles'
import { Check } from 'react-feather'

interface CheckBoxProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  toggle: boolean
}

/**
 * CheckBox
 * @param toggle - CheckBox toggle
 */

const CheckBox = ({ toggle, ...otherProps }: CheckBoxProps) => {
  // TODO: Fix licking when the package version 9 is not in beta
  const fadeOpacity = useSpring({ opacity: toggle ? 1 : 0 })
  return (
    <CheckBoxContainer simple {...otherProps}>
      <IconContainer style={fadeOpacity}>
        <Check size={11} color="white" />
      </IconContainer>
    </CheckBoxContainer>
  )
}

export default CheckBox
