import arrayMove from 'array-move'
import update from 'immutability-helper'
import debounce from 'lodash.debounce'
import React, { useRef, useState } from 'react'
import { match } from 'react-router'
import { ValueType } from 'react-select/src/types'
import { SortableElement } from 'react-sortable-hoc'
import { animated } from 'react-spring'
import Button from '../../../../components/Button'
import Container from '../../../../components/Container'
import { InputGroup } from '../../../../components/InputGroup'
import InputSection from '../../../../components/InputSection'
import InputText from '../../../../components/InputText'
import Spinner from '../../../../components/Spinner'
import Toast from '../../../../components/Toast'
import useSingleFleet from '../../../../config/hooks/useSingleFleet'
import history from '../../../../config/router'
import { fetchCustomers } from '../../../../services/customers'
import { createFleet, updateFleet } from '../../../../services/fleets'
import { updateSiloId } from '../../../../services/silos'
import { PromiseReject, PromiseResolve } from '../../../../services/types/base'
import Modems, { SortContainer } from './components/Modems'
import {
  AddAnotherButton,
  AddEditFooter,
  BackTo,
  CustomAsyncSelect,
  CustomerInputWrapper,
  ErrorMessage,
} from './styles'

interface FleetAddEditProps {
  match: match<{ id: string }>
}

const FleetAddEdit = ({ match }: FleetAddEditProps) => {
  const { id } = match.params
  const [loading, setLoading] = useState(false)
  const inputRef = useRef<HTMLLabelElement>(null)
  const [assignSilosError, setAssignSilosError] = useState('')
  const [locationError, setLocationError] = useState('')
  const {
    fleetId,
    customerInput,
    setCustomerInput,
    assignedSilos,
    setAssignedSilos,
    setFleetId,
    location,
    setLocation,
    modemList,
    loadingData,
    props,
    toast,
    setToast,
  } = useSingleFleet(id)
  const [fleetIdError, setFleetIdError] = useState('')

  const SortableItem = SortableElement(({ modem, modemIndex }: any) => {
    return (
      <li>
        <Modems
          key={modemIndex}
          modem={modem}
          handleModemChange={handleModemChange}
          index={modemIndex}
          removeModemFromArray={removeModemFromArray}
          loading={loading}
          modemList={handleModemList()}
          error={undefined}
        />
      </li>
    )
  })

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const movedSilos = arrayMove(assignedSilos, oldIndex, newIndex)
    if (movedSilos) {
      const newArray: any[] = movedSilos.map((s: any, index) => {
        return assignedSilos.find(a => {
          if (a.modemId === s.modemId) {
            const newOrderedSilo = a
            a.assignedSiloSortOrder = index

            return newOrderedSilo
          }
          return null
        })
      })
      setAssignedSilos(newArray)
    }
  }

  const searchCustomersByName = async (
    name: string,
    resolve: PromiseResolve<Array<{ value: string; label: string }>>,
    reject: PromiseReject<Error>,
  ) => {
    try {
      if (!name) {
        resolve([])
        return []
      }

      // Search customers by name
      const response = await fetchCustomers({ name, take: 4, skip: 0 })

      // Transform customers into input options
      const customerInputOptions = response.data.data.customers.map(({ id, name }): {
        value: string
        label: string
      } => ({
        value: id,
        label: name,
      }))

      resolve(customerInputOptions)
      return customerInputOptions
    } catch (error) {
      reject(error)
      setToast({ value: error.message, type: 'error' })
    }

    return null
  }

  // Create reference to debounced function search customers
  const debouncedSearchCustomersByName = useRef(debounce(searchCustomersByName, 750)).current

  // Wrap function to deliver consistently a Promise
  const handleSearchCustomersByName = (
    inputValue: string,
  ): Promise<
    Array<{
      label: string
      value: string
    }>
  > =>
    new Promise((resolve, reject) => {
      debouncedSearchCustomersByName(inputValue, resolve, reject)
    })

  const handleModemChange = (value: string, i: number, inputName: string) => {
    if (inputName === 'alias') {
      const modem = modemList.find(item => item.alias === value)
      const newIndex = i ? i : assignedSilos.length - 1
      const newModems = update(assignedSilos, {
        [newIndex]: {
          modemId: { $set: modem && modem.mdmid ? modem.mdmid : '' },
          assignedSilo: { $set: modem && modem.siloId ? modem.siloId : '' },
          assignedSiloUid: { $set: modem && modem.siloUid ? modem.siloUid : '' },
        },
      })
      setAssignedSilos(newModems)
      return
    }
    const newModems = update(assignedSilos, {
      [i]: { assignedSilo: { $set: value } },
    })
    setAssignedSilos(newModems)
    return
  }
  const addNewModemRow = () => {
    setAssignedSilos([
      ...assignedSilos.sort((a, b) => {
        return a.assignedSiloSortOrder - b.assignedSiloSortOrder
      }),
      { modemId: '', assignedSilo: '', assignedSiloUid: '', assignedSiloSortOrder: assignedSilos.length },
    ])
  }

  const removeModemFromArray = (i: number) => {
    const removedModems = update(assignedSilos, { $splice: [[i, 1]] })
    // tslint:disable-next-line: no-increment-decrement
    for (let j = 0; j < removedModems.length; j++) {
      removedModems[j].assignedSiloSortOrder = j
    }
    setAssignedSilos(removedModems)
  }

  const clearErrors = () => {
    if (fleetIdError) setFleetIdError('')
    if (assignSilosError) setAssignSilosError('')
    if (locationError) setLocationError('')
  }
  const handleCreate = async () => {
    clearErrors()

    let errors = 0
    if (!fleetId) {
      setFleetIdError('This field is required, please add a custom id for this fleet.')
      if (inputRef && inputRef.current) inputRef.current.focus()
      errors += 1
    }

    if (!location) {
      setLocationError('This field is required, please add a location for this fleet.')
      errors += 1
    }
    if (assignedSilos.length > 0) {
      const checkForEmpty = assignedSilos.some(({ assignedSilo, modemId }) => !assignedSilo || !modemId)
      if (checkForEmpty) {
        setAssignSilosError('Some of the values are empty. Please fill the missing ones or remove the line.')
        errors += 1
      }
    }
    if (errors > 0) {
      return
    }
    try {
      setLoading(true)
      // * Collect all the assignedSilos in an array of strings siloUid
      const selectedSilos: string[] = []
      assignedSilos.forEach(async silo => {
        const select = modemList.find(modem => modem.mdmid === silo.modemId)
        if (select) {
          selectedSilos.push(select.siloUid)
          try {
            await updateSiloId(silo.assignedSiloUid, silo.assignedSilo, silo.assignedSiloSortOrder)
          } catch (error) {
            console.log({ error })
          }
        }
      })

      const customerUid = customerInput ? customerInput.value : null

      if (id) {
        const response = await updateFleet(id, fleetId, location, customerUid, selectedSilos)
        setToast({ value: response.data.message })
        history.push('/fleets')
        return
      }
      const response = await createFleet(fleetId, location, customerUid, selectedSilos)
      // * Reset to inital state:
      setFleetId('')
      setCustomerInput({ value: '', label: '' })
      setLocation('')
      setAssignedSilos([{ modemId: '', assignedSilo: '', assignedSiloUid: '', assignedSiloSortOrder: 0 }])
      setToast({ value: response.data.message })
      setLoading(false)
    } catch (error) {
      console.log({ error })
      setToast({ value: error.message, type: 'error' })
      setLoading(false)
    }
  }
  const handleModemList = () => {
    const newList = modemList.filter(item => !assignedSilos.find(x => x.modemId === item.mdmid)).map(item => item.alias)
    return newList
  }

  if (loadingData) {
    return <Spinner message="Fetching Data..." />
  }

  return (
    <>
      <Toast message={toast} action={setToast} />
      <animated.div style={props}>
        <Container title={id ? 'Update Fleet' : 'Create Fleet'} secondary>
          <BackTo to="/fleets" icon="ArrowLeft">
            Back to Fleet
          </BackTo>
          <InputSection title="Add Silo">
            <InputGroup>
              <InputText
                disabled={loading}
                label="Fleet ID"
                value={fleetId}
                onChange={e => setFleetId(e.target.value)}
                required
                error={fleetIdError}
                labelRef={inputRef}
              />
              <CustomerInputWrapper
                label="Assign Customer"
                info="Start typing customer name to reveal options."
                disabled={loading}
              >
                <CustomAsyncSelect
                  isClearable
                  cacheOptions
                  placeholder=""
                  defaultOptions
                  onChange={(value: ValueType<any>) => setCustomerInput(value)}
                  loadOptions={handleSearchCustomersByName}
                  value={customerInput || ''}
                  isDisabled={loading}
                />
              </CustomerInputWrapper>
            </InputGroup>
            <InputGroup>
              <InputText
                required
                disabled={loading}
                value={location}
                onChange={e => setLocation(e.target.value)}
                error={locationError}
                label="Location"
              />
              <div />
            </InputGroup>
          </InputSection>
          <InputSection title="Assign Silos" subtitle="Please assign any existing silos that a customer has.">
            {assignedSilos && (
              <SortContainer onSortEnd={onSortEnd} useDragHandle>
                {assignedSilos
                  .sort((a, b) => {
                    return a.assignedSiloSortOrder - b.assignedSiloSortOrder
                  })
                  .map((modem, index) => {
                    return <SortableItem key={`item-${modem.modemId}`} index={index} modemIndex={index} modem={modem} />
                  })}
              </SortContainer>
            )}

            {assignSilosError && <ErrorMessage>{assignSilosError}</ErrorMessage>}
          </InputSection>
          <AddAnotherButton icon="PlusCircle" simple onClick={addNewModemRow}>
            Add Another
          </AddAnotherButton>
        </Container>
        <AddEditFooter>
          <Button onClick={handleCreate} loading={loading}>
            {id ? 'Update' : 'Create'} Fleet
          </Button>
        </AddEditFooter>
      </animated.div>
    </>
  )
}
export default FleetAddEdit
