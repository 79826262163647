import React, { useState } from 'react'
import { match } from 'react-router'
import { animated } from 'react-spring'
import Button from '../../../../components/Button'
import CheckBox from '../../../../components/CheckBox'
import Container from '../../../../components/Container'
import { InputGroup } from '../../../../components/InputGroup'
import InputSection from '../../../../components/InputSection'
import InputTag from '../../../../components/InputTag'
import InputText from '../../../../components/InputText'
import Modal from '../../../../components/Modal'
import Spinner from '../../../../components/Spinner'
import Toast from '../../../../components/Toast'
import useSingleCustomerUser from '../../../../config/hooks/useSingleCustomerUser'
import history from '../../../../config/router'
import { validateEmail } from '../../../../config/utils/functions'
import { checkIfUserExist, createUser, deleteUser, updateUser } from '../../../../services/user'
import { AddEditFooter, BackTo, CheckBoxContainer, DivBlock } from './styles'

interface CustomerUserAddEditProps {
  match: match<{ id?: string; customerUid?: string }>
}

const CustomerUserAddEdit = ({ match }: CustomerUserAddEditProps) => {
  const { id, customerUid: cUid } = match.params
  const [loading, setLoading] = useState(false)
  const [toggleDelete, setToggleDelete] = useState(false)
  const [userEmailError, setUserEmailError] = useState('')
  const [assignedFleetsError, setAssignedFleetsError] = useState('')
  const [inputValueAssignedFleets, setInputValueAssignedFleets] = useState('')

  const {
    oldEmail,
    email,
    setEmail,
    customerFleets,
    assignedFleets,
    setAssignedFleets,
    checked,
    setChecked,
    userName,
    setUserName,
    customerUid,
    loadingData,
    toast,
    setToast,
    props,
  } = useSingleCustomerUser({ userUid: id, customerUid: cUid })

  const resetErrors = () => {
    if (userEmailError) {
      setUserEmailError('')
    }
    if (assignedFleetsError) {
      setAssignedFleetsError('')
    }
  }

  const handleCreate = async () => {
    try {
      setLoading(true)
      resetErrors()
      let errors = 0
      if (!email) {
        setUserEmailError('User email is required, please enter a email.')
        errors += 1
      }

      if (!validateEmail(email)) {
        setUserEmailError("The entered user email don't have a proper format, please add a valid email.")
        errors += 1
      }

      if (!checked && assignedFleets.length === 0) {
        setAssignedFleetsError(
          'Please assign some fleets to this user or select the checkbox to assign all fleets to this user.',
        )
        errors += 1
      }

      if (oldEmail !== email) {
        const userExist = await checkIfUserExist(email)

        if (userExist) {
          setUserEmailError('This user already exist on the database, please add a new one.')
          errors += 1
        }
      }

      // * Transform the assigned fleetUids to fleetIds
      const userFleetUids: string[] = []
      if (checked) {
        customerFleets.map(f => userFleetUids.push(f.fleetUid))
      }
      assignedFleets.map(fleetId => {
        const findFleet = customerFleets.find(fleet => fleet.fleetId === fleetId)
        if (findFleet) return userFleetUids.push(findFleet.fleetUid)
        setAssignedFleetsError('Some of the assigned fleets dont exist or belong to this customer.')
        errors += 1
        return null
      })

      if (errors > 0) {
        setLoading(false)
        return
      }

      if (id) {
        const result = await updateUser(
          id,
          'EXTERNAL_USER',
          undefined,
          userName,
          email,
          undefined,
          checked ? undefined : userFleetUids,
        )
        history.push(`/customers/${customerUid}`, { routerNotification: result.data.message })
      } else {
        const result = await createUser('EXTERNAL_USER', userName, email, undefined, customerUid, userFleetUids)
        setToast({ value: result.data.message })
        setEmail('')
        setAssignedFleets([])
        setUserName('')
      }
      setLoading(false)

      return
    } catch (error) {
      setLoading(false)
      setToast({ value: error.message, type: 'error' })
    }
  }

  const handleDeleteModal = () => {
    setToggleDelete(true)
  }

  const handleRemoveUser = async () => {
    try {
      setLoading(true)
      if (id) await deleteUser(id)
      history.push(`/customers/${customerUid}`, { routerNotification: 'User deleted successfully' })
    } catch (error) {
      setToast({ value: error.message, type: 'error' })
    }

    setLoading(false)
  }

  const autocompleteFleets = (value: string) => {
    const customerFleetListId: string[] = []
    customerFleets.filter(
      x => x.fleetId.toLowerCase().startsWith(value.toLowerCase()) && customerFleetListId.push(x.fleetId),
    )
    return customerFleetListId
  }

  if (loadingData) {
    return <Spinner message="Fetching Data..." />
  }

  return (
    <>
      <Modal toggle={toggleDelete} toggleFunction={setToggleDelete} title="Remove confirmation">
        <div>
          <p>{`Are you sure you want to remove ${userName}?`}</p>
          <Button negative onClick={handleRemoveUser}>
            Remove
          </Button>
        </div>
      </Modal>
      <Toast message={toast} action={setToast} />
      <animated.div style={props}>
        <Container title={id ? 'Update Customer User' : 'Create Customer User'} secondary>
          <BackTo to={`/customers/${customerUid}`} icon="ArrowLeft">
            Back to Customer
          </BackTo>
          <InputSection title={id ? 'Update User' : 'Create User'}>
            <DivBlock>
              <InputGroup>
                <InputText
                  value={email}
                  label="Email Address"
                  placeholder="User Email"
                  onChange={e => setEmail(e.target.value)}
                  required
                  disabled={loading}
                  error={userEmailError}
                />

                <InputText
                  label="User Name"
                  placeholder="Enter a user name"
                  onChange={e => setUserName(e.target.value)}
                  value={userName}
                  disabled={loading}
                  required
                />

                <div>
                  <InputTag
                    label="Assigned Fleets"
                    disabled={loading || checked}
                    placeholder={
                      assignedFleets.length === 0 ? (checked ? 'All Fleets Assigned' : 'Type Fleet ids') : ''
                    }
                    items={assignedFleets}
                    setItems={value => setAssignedFleets(value)}
                    info="Please press enter key to submit values"
                    error={assignedFleetsError}
                    searchFN={autocompleteFleets}
                    value={inputValueAssignedFleets}
                    setValue={setInputValueAssignedFleets}
                    required
                    full
                  />
                  <CheckBoxContainer>
                    <CheckBox
                      toggle={checked}
                      onClick={() => {
                        setChecked(!checked)
                        setAssignedFleets([])
                      }}
                    />
                    <p>Assign All Fleets</p>
                  </CheckBoxContainer>
                </div>
              </InputGroup>
            </DivBlock>
          </InputSection>
        </Container>
        <AddEditFooter>
          {id && (
            <Button negative onClick={handleDeleteModal}>
              Remove User
            </Button>
          )}
          <Button onClick={handleCreate} loading={loading}>
            {id ? 'Update' : 'Create'} User
          </Button>
        </AddEditFooter>
      </animated.div>
    </>
  )
}

export default CustomerUserAddEdit
