import React, { useState, RefObject, useRef } from 'react'
import { X } from 'react-feather'
import { InputTagContainer, ItemContainer, Item, InputTagStyled, Option, OptionList, LoadingDebounce } from './styles'
import { useTransition, config, animated } from 'react-spring'
import InputWrapper from '../InputWrapper'
import useDebounce from 'react-use/lib/useDebounce'

interface InputTagProps extends React.InputHTMLAttributes<HTMLDivElement> {
  items: string[]
  setItems: React.Dispatch<React.SetStateAction<any>>
  full?: boolean
  label?: string
  error?: string
  placeholder?: string
  info?: string
  labelRef?: RefObject<HTMLLabelElement>
  searchFN?: (value: string) => Promise<Array<string>> | string[]
  setValue?: React.Dispatch<React.SetStateAction<string>>
}

/**
 * InputTag
 * @param items - Array of strings
 * @param setItems - Pass the change state function (hook)
 * @param full - With to 100%
 * @param searchFN - This will activate the autocomplete, Pass the fetch data function returning an array of strings
 * @param setValue - This will be the input value (required for searchFN)
 * @param label - Label text, also acts as the id
 * @param error - Display an error message on the bottom of the component
 * @param placeholder - Display a placeholder for the input
 * @param info - Info text for extra description
 * @param labelRef - Create a reference for the label, use cases: focus on error
 */

const InputTag = ({
  label,
  labelRef,
  error,
  placeholder,
  info,
  items,
  setItems,
  full,
  searchFN,
  setValue,
  ...otherProps
}: InputTagProps) => {
  const [highlight, setHighlight] = useState<string>('')
  const [loadingDebounce, setLoadingDebounce] = useState(false)
  const [options, setOptions] = useState<Array<string>>([])

  const transitions = useTransition(items, item => item, {
    from: { transform: 'scale3d(0,0,0)' },
    enter: { transform: 'scale3d(1,1,1)' },
    leave: { transform: 'scale3d(1,1,1)' },
  })

  const autoCompleteTransitions = useTransition(options, option => option, {
    from: { opacity: 0, transform: 'translate3d(30px,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(30px,0,0)' },
    trail: 200,
    config: config.gentle,
  })

  useDebounce(
    async () => {
      if (searchFN && loadingDebounce && otherProps.value) {
        const valueToString = otherProps.value.toString()
        const filter: Array<string> = await searchFN(valueToString)
        setOptions(filter)
      }
      setLoadingDebounce(false)
    },
    2000,
    [otherProps.value],
  )

  const onClicked = (item: string) => {
    if (searchFN && item && item.trim()) {
      setHighlight('')
      const exist = items.some(x => x === item)

      if (!exist) {
        setItems([...items, item])
        otherProps.value = ''
        return
      }
      setHighlight(item)
      otherProps.value = ''
      setTimeout(() => {
        setHighlight('')
      }, 1000)
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    if (event.key === 'Enter' && value && value.trim()) {
      setHighlight('')
      const exist = items.some(x => x === value)

      if (!exist) {
        setItems([...items, value])
        event.currentTarget.value = ''
        setOptions([])
        return
      }
      setHighlight(value)
      event.currentTarget.value = ''
      setOptions([])
      setTimeout(() => {
        setHighlight('')
      }, 1000)
    }
  }

  const removeItem = (item: string) => {
    setHighlight('')
    const filterItem = items.filter(x => x !== item)
    setItems(filterItem)
  }

  const ref = useRef<HTMLInputElement>(null)

  return (
    <div style={{ position: 'relative' }}>
      <InputWrapper error={error} info={info} label={label} labelRef={labelRef}>
        {searchFN && loadingDebounce && <LoadingDebounce>loading</LoadingDebounce>}
        <InputTagContainer full={full} {...otherProps}>
          {transitions.map(({ item, props, key }) => {
            return (
              <ItemContainer
                simple
                highlight={highlight === item}
                key={key}
                style={props}
                onClick={() => removeItem(item)}
              >
                <Item>{item}</Item>
                <X size={12} color="white" />
              </ItemContainer>
            )
          })}
          <InputTagStyled
            type="text"
            placeholder={placeholder}
            id={label}
            disabled={otherProps.disabled}
            onKeyPress={e => handleKeyPress(e)}
            onChange={e => {
              setLoadingDebounce(true)
              if (setValue) {
                setValue(e.target.value)
              }
            }}
            ref={ref}
          />
        </InputTagContainer>
      </InputWrapper>
      {setValue && (
        <OptionList>
          {autoCompleteTransitions.map(({ item, props, key }) => (
            <animated.li key={key} style={props}>
              <Option
                simple
                onClick={() => {
                  onClicked(item)
                  setOptions([])
                  if (ref && ref.current) {
                    setValue('')
                    ref.current.value = ''
                  }
                }}
              >
                {item}
              </Option>
            </animated.li>
          ))}
        </OptionList>
      )}
    </div>
  )
}

export default InputTag
